import React, { useState, useEffect, useContext } from "react"
import PropTypes from "prop-types"

import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
// Redux
import { Link, useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"

// users
import user1 from "../../../assets/images/users/avatar-1.jpg"
import { AuthContext } from "context/AuthContext"
import { GET_USER } from "graphql/queries/user"
import useCustomQuery from "graphql/hooks/useCustomQuery"

const ProfileMenu = props => {
  const navigate = useNavigate()
  const [imageName, setImageName] = useState('')
  const [moduleType, setModuleType] = useState('')

  const { user, dispatch } = useContext(AuthContext)
  const currentUserId = JSON.parse(localStorage.getItem('authUser'))

  var { loading, error, data: userData, refetch } = useCustomQuery(GET_USER, {
    variables: { getUserByIdId: currentUserId._id },
  })

  useEffect(() => {

    if (userData) {
      setImageName(userData?.GetUserById?.image?.image)
      setModuleType(userData?.GetUserById?.image?.moduleType)
    }
  }, [userData])
  const logout = () => {
    sessionStorage.removeItem("OAuthUser")
    dispatch({ type: "LOGOUT" })
    navigate("/login")
  }

  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)
  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          {/* <img
            className="rounded-circle header-profile-user"
            src={`${process.env.REACT_APP_APOLLO_SERVER_URL}/${currentUserId.organization.name}/${moduleType}/${imageName}`}
            alt="Header Avatar"
          /> */}
          <span className="d-none d-xl-inline-block ms-2 me-1">
            {user?.fullName}
          </span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {/* <DropdownItem tag="a" href="/domainsetting">
            {" "}
            <i className="bx bx-analyse font-size-16 align-middle me-1" />
            {props.t("Domain Setting")}
            {""}
          </DropdownItem> */}
          {/* <DropdownItem tag="a" href="/subdomainsetting">
            {" "}
            <i className="bx bx-analyse font-size-16 align-middle me-1" />
            {props.t("Sub-Domain Setting")}
            {""}
          </DropdownItem> */}
          <DropdownItem tag="a" href="/user-profile">
            {" "}
            <Link to="/user-profile" style={{ color: "inherit" }}>
              <i className="bx bx-user font-size-16 align-middle me-1" />
              {props.t("Profile")}
            </Link>
            {""}
          </DropdownItem>
          <div className="dropdown-divider" />
          <a onClick={logout} className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </a>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(ProfileMenu))
