import { gql } from '@apollo/client';

const GET_ORDER_PRODUCTS_BY_ID = gql`
query GetProductOrdersById($getProductOrdersById: ID!) {
  GetProductOrdersById(id: $getProductOrdersById) {
    order_id
    notes
    shipping {
      name
      email
      address
      city
      country
      house_number
      postal_code
      telephone
    }
    items {
      _id
      product_id
      status
      currency
      quantity
      price
      total
      deliveryPartner
      item {
        product_id
        name
        sku
        stock_quantity
        estimate
        packages
        packages_dimensions{
        length
        width
        height
        weight
        }
      }
    } 
      shipments {
        _id,
        shipment_id
        delivery_partner
        label_status
        product_id {
        _id
        item {
        product_id
        name
        sku
        stock_quantity
        estimate
        }
        product_id
        status
        currency
        quantity
        price
        total
        }
      }
  
  }
}
`
const GET_ALL_ORDERS = gql`
query GetAllOrders($page: Int!, $perPage: Int!, $search: String, $filter: String) {
  GetAllOrders(page: $page, perPage: $perPage, search: $search, filter: $filter) {
    totalCount
    orders {
      currency
      order_id
      status
      total
      shipping{
        country
      }
    }
  }
}
`
export { GET_ORDER_PRODUCTS_BY_ID, GET_ALL_ORDERS }
