import { gql } from '@apollo/client';

const UPDATE_PRODUCT = gql`
  mutation Mutation($updateProductId: ID!, $input: UpdateProductInput!) {
    UpdateProduct(id: $updateProductId, input: $input) {
      _id
      product_id
      name
    }
  }
`

const UPDATE_SUPPLIER_PRODUCT = gql`
mutation UpdateSupplierProduct($sku: String!, $input: UpdateSupplierProductInput!) {
  UpdateSupplierProduct(sku: $sku, input: $input) {
    isProductApproved
  }
}
`

const UPDATE_SUPPLIER_PRICE = gql`
mutation UpdateSupplierPrice($sku: String!, $input: UpdateSupplierProductInput!) {
  UpdateSupplierPrice(sku: $sku, input: $input) {
    isPriceApproved
  }
}
`

const UPDATE_DELIVERY_TIME = gql`
mutation UpdateDeliveryTime($id: ID!, $input: UpdateDeliveryTimeInput!) {
  UpdateDeliveryTime(id: $id, input: $input)
}
`
export {
  UPDATE_PRODUCT,
  UPDATE_SUPPLIER_PRODUCT,
  UPDATE_SUPPLIER_PRICE,
  UPDATE_DELIVERY_TIME
}
