import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const ConfirmationDialog = ({ show, tittle, description, onConfirmClick, onCloseClick }, args) => {

    return (
        <div>
            <Modal isOpen={show} toggle={onCloseClick} centered={true} backdrop={true} {...args}>
                <ModalHeader toggle={onCloseClick}>{tittle}</ModalHeader>
                <ModalBody>
                    {description}
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={onCloseClick}>
                        Cancel
                    </Button>{' '}
                    <Button color="primary" onClick={onConfirmClick}>
                        Confim
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default ConfirmationDialog;