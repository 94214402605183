import React, { useState } from "react";
import {
  Container,
  Card,
  CardBody,
  Label,
  Input,
  Form,
  Row,
  Col,
} from "reactstrap";
import Breadcrumb from "../../components/Common/Breadcrumb";
import PuffLoading from "components/Loading/PuffLoading";
import { withTranslation } from "react-i18next"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useMutation } from "@apollo/client";
import { CREATE_LABELS } from "graphql/mutations/labelPrinting";

const PARTNERS = [
  { value: 68, label: "DPD" },
  { value: 6, label: "PostNL" }
]
function LabelManagement(props) {
  const formState = {
    order_id: "",
    quantity: "",
    partner: 68
  }
  const [labels, setLabels] = useState([])
  const [createLabel, { loading }] = useMutation(CREATE_LABELS)

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: JSON.parse(JSON.stringify(formState)),
    validationSchema: Yup.object({
      order_id: Yup.number().required(
        "Please Enter Order ID"
      ),
      quantity: Yup.number().required().required("Please Enter Quantity").positive().integer(),
      partner: Yup.number().required().required("Please Select Partner").positive().integer()
    }),
    onSubmit: values => {
      const formBody = {
        order_id: values.order_id,
        quantity: values.quantity,
        partner: parseInt(values.partner)
      }
      createLabel({
        variables: {
          input: formBody
        }
      }).then(res => {
        if (res.data.CreateLabels) {
          setLabels(res.data.CreateLabels)
        }
      })
    },
  })

  document.title = props.t("Label Management")
  return (
    <React.Fragment>
      {loading && <PuffLoading />}
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Label Management" breadcrumbItem="Label" />

          <Card>
            <CardBody>
              <Row style={{ rowGap: 10 }}>
                <Col md={6} lg={6}>
                  <Form onSubmit={validation.handleSubmit}
                  >
                    <div>
                      <Label className="form-label">Order ID</Label>
                      <Input
                        name="order_id"
                        placeholder="Enter Order ID"
                        type="number"
                        onChange={validation.handleChange}
                        min={0}
                        onBlur={validation.handleBlur}
                        value={validation.values.order_id}
                        invalid={validation.touched.order_id &&
                          validation.errors.order_id}
                      />
                      {validation.touched.order_id &&
                        validation.errors.order_id && (
                          <div className="text-danger">
                            {validation.errors.order_id}
                          </div>
                        )}
                    </div>
                    <div>
                      <Label className="form-label mt-2">Label Quantity</Label>
                      <Input
                        name="quantity"
                        className="form-control"
                        placeholder="Enter Label Quantity"
                        type="number"
                        min={1}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.quantity}
                        invalid={validation.touched.quantity &&
                          validation.errors.quantity}
                      />
                      {validation.touched.quantity &&
                        validation.errors.quantity && (
                          <div className="text-danger">
                            {validation.errors.quantity}
                          </div>
                        )}
                    </div>
                    <div>
                      <Label className="form-label mt-2">Delivery Partner</Label>
                      <select
                        name="partner"
                        className="form-control"
                        placeholder="Select Delivery Partner"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.partner}
                      >
                        {PARTNERS?.map((item, i) => (
                          <option
                            key={i}
                            value={item.value}
                          >
                            {item.label}
                          </option>
                        ))}
                      </select>
                      {validation.touched.partner &&
                        validation.errors.partner && (
                          <div className="text-danger">
                            {validation.errors.partner}
                          </div>
                        )}
                    </div>
                    <div className="mt-4">
                      <button type="submit" className="btn btn-primary waves-effect waves-light">
                        Print Labels
                      </button>
                    </div>
                  </Form>
                </Col>
                <Col md={6} lg={6}>
                  {labels && labels?.length > 0 && <h5>Print Labels</h5>}
                  {labels && labels?.map((label, index) => (
                    <a
                      key={index}
                      target="_blank"
                      rel="noreferrer"
                      href={`${process.env.REACT_APP_APOLLO_SERVER_URL}/${label}`}
                      className="btn btn-success d-block mb-2 waves-effect waves-light"
                      style={{ width: 150 }}
                    >
                      Label {index + 1}
                    </a>
                  ))}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment >
  )
}

LabelManagement.propTypes = {
  t: PropTypes.any,
}

export default withRouter(withTranslation()(LabelManagement))