import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
} from "reactstrap";
import Breadcrumb from "../../components/Common/Breadcrumb";
import avatar from "../../assets/images/users/avatar-1.jpg";
// import { GET_USER } from "graphql/queries/user";
// import useCustomQuery from "../../graphql/hooks/useCustomQuery";
// import useCustomMutation from "../../graphql/hooks/useCustomMutation";
import { useNavigate, useParams } from "react-router-dom";
// import { UPDATE_USER_MUTATION } from "../../graphql/mutations/user";
import { toast } from "react-toastify"
import moment from "moment";


function EditUser({ isEdit, isView }) {
  const params = useParams();
  const [idx, setidx] = useState(1);
  const [userId, setUserId] = useState();
  const [userData, setUserData] = useState([])
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [status, setStatus] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState("");
  const [loyaltyPoints, setLoyaltyPoints] = useState()
  let navigate = useNavigate();


  const [socialPlatform, setSocialPlatform] = useState([{ id: 1, value: "" }])

  const [platform, setPlatform] = useState("");


  var [UpdateUser, { loading, error }] = useCustomMutation(
    UPDATE_USER_MUTATION
  )

  var { loading, error, data, execute } = useCustomQuery(GET_USER, {
    variables: { getUserByIdId: params.UserId },
  })


  useEffect(() => {
    if (data != null) {
      const baseData = data?.GetUserById
      setName(baseData?.fullName)
      setEmail(baseData?.email)
      setPhoneNumber(baseData?.phoneNum)
      setLoyaltyPoints(baseData?.loyaltyPoints)
      if (baseData?.blacklistedAt == null || baseData?.blacklistedAt == "" || baseData?.blacklistedAt == "false") {
        setStatus(false)
      } else {
        setStatus(true)
      }
      setSocialPlatform(
        baseData?.socialPlatform?.map((value, i) => {
          return { id: i + 1, value }
        })
      )
    }

  }, [data])



  const updateProfile = (e) => {
    e.preventDefault();
    if (phoneNumber == null || phoneNumber == "") {
      return toast.error("Phone number can't be empty")
    } else {

      let blackListed = ""
      if (status == true) {
        blackListed = "true"
      } else {
        blackListed = "false"
      }

      const allInputs = {
        fullName: name,
        email: email,
        phoneNum: phoneNumber,
        loyaltyPoints: parseInt(loyaltyPoints),
        blacklistedAt: blackListed,
        socialPlatform: socialPlatform?.map(social => social.value),
      }


      UpdateUser("UpdateUser", { input: allInputs, updateUserId: params.UserId }).then(user => {
        if (user) {
          toast.success("Account Updated Successfully!")
          navigate(-1)
        }
      })
        .catch(err => {

        })
    }



  }

  function handleRemoveRow(id) {
    var modifiedRows = [...socialPlatform]
    modifiedRows = modifiedRows.filter(x => x["id"] != id)
    setSocialPlatform(modifiedRows)
  }

  const handleChange = e => {
    const { name, value } = e.target
    if (name === "socialPlatform") {
      if (e.target.id) {
        setSocialPlatform(
          socialPlatform?.map(social => {
            if (social.id?.toString() === e.target.id?.toString()) {
              social.value = value
            }
            return social
          })
        )
      }
    }
  }

  function handleAddRowNested() {
    const modifiedRows = [...socialPlatform]
    modifiedRows.push({ id: modifiedRows.length + 1, value: "" })
    setSocialPlatform(modifiedRows)
  }




  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}

          <Breadcrumb title="Skote" breadcrumbItem="User's Profile" />

          <Row>
            <Col lg="12">
              {/* {error && error ? <Alert color="danger">{error}</Alert> : null}
              {success ? <Alert color="success">{success}</Alert> : null} */}

              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="ms-3">
                      <img
                        src={avatar}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <h5>{name}</h5>
                        <p className="mb-1">{email}</p>
                        <p className="mb-0">Id no: #{idx}</p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <h4 className="card-title mb-4">Edit Profile</h4>

          <Card>
            <CardBody>
              <Form
                className="form-horizontal"
                onSubmit=""
              >
                <div className="form-group">
                  <Label className="form-label">Full Name</Label>
                  <Input
                    name="fullname"
                    className="form-control"
                    placeholder="Enter Full Name"
                    type="text"
                    disabled={isView}
                    value={name}
                    onChange={(e) => setName(e.target.value)}

                  />
                  <Label className="form-label">Phone Number</Label>
                  <Input
                    name="phoneNumber"
                    className="form-control"
                    placeholder="Enter Phone Number"
                    required
                    disabled={isView}
                    type="number"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}

                  />
                  <Label className="form-label">Email</Label>
                  <Input
                    name="email"
                    className="form-control"
                    placeholder="Enter Email"
                    type="email"
                    disabled={isView}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <Label className="form-label">Loyalty points</Label>
                  <Input
                    name="loyaltyPoints"
                    className="form-control"
                    placeholder="Enter loyalty points"
                    type="number"
                    disabled={isView}
                    value={loyaltyPoints}
                    onChange={(e) => setLoyaltyPoints(e.target.value)}
                  />
                  {isView ? (null) : (
                    <div className="mt-3">
                      <div className="mb-3">
                        <label className="control-label">Activate/Deactivate</label>
                        <div className="square-switch">
                          <input
                            type="checkbox"
                            id="status"
                            className="switch"
                            checked={status}
                            onClick={e => {
                              setStatus(!e.target.checked)
                            }}
                          />
                          <label
                            htmlFor="status"
                          />
                        </div>

                      </div>

                    </div>
                  )}


                  <Label>Social Platform:</Label>
                  <table className="table table-borderless" style={{ width: "100%" }}>
                    <tbody>
                      {socialPlatform?.map((formRow, key) => {
                        return (
                          <tr key={key} className="bg-white mb-0 p-0">
                            <td className="p-0">
                              <Row className="mb-1">
                                <Col md="10">
                                  <Input
                                    type="text"
                                    className={
                                      isView
                                        ? "form-control ps-0 pl-0"
                                        : "inner form-control"
                                    }
                                    placeholder="Enter Social Platform..."
                                    name="socialPlatform"
                                    id={formRow?.id}
                                    onChange={handleChange}
                                    value={formRow?.value}
                                    required
                                    disabled={isView}
                                    style={{
                                      backgroundColor: isView
                                        ? "transparent"
                                        : "initial",
                                      border: isView ? "none" : "",
                                    }}
                                  />
                                </Col>

                                {socialPlatform?.length > 1 &&
                                  !false &&
                                  key > 0 && (
                                    <Col md="2">
                                      <Button
                                        color="primary"
                                        className="btn-block inner"
                                        id="unknown-btn"
                                        style={{ width: "100%" }}
                                        onClick={e => {
                                          handleRemoveRow(formRow?.id)
                                        }}
                                      >
                                        {" "}
                                        Delete{" "}
                                      </Button>
                                    </Col>
                                  )}
                              </Row>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                    {!false && (
                      <Col md="2">
                        {isView == true ? (
                          null
                        ) : (
                          <Button
                            onClick={() => {
                              handleAddRowNested()
                            }}
                            style={{ width: "100%" }}
                            color="success"
                          >
                            Add Social Platform
                          </Button>
                        )}

                      </Col>
                    )}
                  </table>






                  {/* <Input name="idx" value={idx} type="hidden" /> */}
                </div>
                <div className="text-center mt-4">
                  {isView == true ? (
                    null
                  ) : (
                    <Button onClick={updateProfile} type="submit" color="danger">
                      Update Profile
                    </Button>
                  )}

                </div>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EditUser
