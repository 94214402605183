import React, { useEffect } from 'react'

import ShipLoader from '../../assets/images/shipment.gif'

const ShipmentLoader = ({ messageText = 'Creating a shipment...' }) => {


  const [gifLoaded, setGifLoaded] = React.useState(false);

  useEffect(() => {
    const img = new Image();
    img.onload = () => setGifLoaded(true);
    img.src = ShipLoader;
  }, [ShipLoader])


  return (
    <div className='overlay'>
      <style>
        {`
          .overlay {
            display:flex;
            align-items:center;
            justify-content: center;
            width: 100%;
            height:100%;
            background-color: #0000006b;
            position:fixed;
            top:0;
            left:0;
            z-index:99999;
          }
          .shipment-loader-wrapper {
            background-color: #fff;
            border-radius: 20px;
            // padding: 20px 0 0 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            max-width: 380px; 
            width: 100%;
             height: 100%;
              max-height: 350px;
            align-items: center;
          }
          .loader-text {
            font-size: 24px;
            margin-top: 24px;
            position: relative;
            color: #556ee6;
          }
          .loader-img {
            height: auto;
            width: 200px;
          }
          // .loader-text:before {
          //   content: attr(data-text);
          //   position: absolute;
          //   overflow: hidden;
          //   max-width: 100%;
          //   white-space: nowrap;
          //   color: #556ee6;
          //   animation: loading 3s linear;
          // }
          // @keyframes loading {
          //   0% {
          //     max-width: 0;
          //   }
          // }
        `}
      </style>

      <div className="shipment-loader-wrapper">
        {gifLoaded && <img src={ShipLoader} alt={messageText} className="loader-img" />}
        <h3 className="loader-text" data-text={messageText}>{messageText}</h3>
      </div>

    </div>
  )
}

export default ShipmentLoader