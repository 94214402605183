import React from "react"
import { Oval } from "react-loader-spinner"
import "./style.scss"
function OvalLoading() {
  return (
    <div className="spinner_loader oval_loader">
      <Oval
        height={40}
        width={40}
        color="#4fa94d"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel="oval-loading"
        secondaryColor="#4fa94d"
        strokeWidth={3}
        strokeWidthSecondary={2}
      />
    </div>
  )
}

export default OvalLoading
