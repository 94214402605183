import React from "react"
import { Link, useNavigate } from "react-router-dom"
import {
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap"
// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

import AuthFooter from "./AuthFooter"
import { FORGET_PASSWORD_MUTATION } from "../../graphql/mutations/user"
import useCustomMutation from "../../graphql/hooks/useCustomMutation"
import PuffLoading from "components/Loading/PuffLoading"
import { toast } from "react-toastify"

const RecoverPassword = () => {
  //meta title
  document.title = "Recover Password"
  const [ForgetPassword, { loading, error }] = useCustomMutation(
    FORGET_PASSWORD_MUTATION
  )
  const navigate = useNavigate()

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Please Enter Your Email")
        .matches(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          "Please enter a valid email address"
        ),
    }),
    onSubmit: values => {
      const input = {
        email: values.email,
      }
      ForgetPassword("ForgetPassword", { input })
        .then(response => {
          if (response) {
            if (response) {
              toast.success(response.message)
              sessionStorage.setItem(
                "user",
                JSON.stringify({
                  email: response.user.email,
                  isVerified: false,
                })
              )
              setTimeout(() => {
                navigate("/verify-otp")
              }, 1000)
            }
          }
        })
        .catch(err => {
          console.error(err)
        })
    },
  })
  return (
    <React.Fragment>
      {loading && <PuffLoading />}
      <div>
        <Container fluid className="p-0 login-container-center">
          <Row className="g-0 login-container">
            <Col >
              <div className="auth-full-page-content p-md-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="my-auto">
                      <div>
                        <h5 className="text-primary"> Reset Password</h5>
                        <p className="text-muted">Reset Password with {process.env.REACT_APP_NAME}</p>
                      </div>

                      <div className="mt-4">
                        <Form
                          className="form-horizontal"
                          onSubmit={e => {
                            e.preventDefault()
                            validation.handleSubmit()
                            return false
                          }}
                        >
                          <div className="mb-3">
                            <Label className="form-label">Email</Label>
                            <Input
                              name="email"
                              className="form-control"
                              placeholder="Enter email"
                              type="email"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              invalid={
                                validation.touched.email &&
                                  validation.errors.email
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.email &&
                              validation.errors.email ? (
                              <FormFeedback type="invalid">
                                {validation.errors.email}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div>
                            <button
                              className="btn btn-primary w-100"
                              type="submit"
                            >
                              Reset
                            </button>
                          </div>
                        </Form>

                        <div className="mt-3 text-center">
                          <p>
                            Remember It ?{" "}
                            <Link
                              to="/login"
                              className="fw-medium text-primary"
                            >
                              {" "}
                              Sign In here
                            </Link>{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="text-center">
                      <AuthFooter />
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default RecoverPassword
