import { gql } from '@apollo/client';

const GetOwnerOrganization = gql`
  query {
    GetOwnerOrganization {
      name
      domain
    }
  }
`
const GetLiveOrganization = gql`
  query Query {
    GetLiveOrganizations {
      _id
      logo
      name
      domain
      description
    }
  }
`
const CheckOrganization = gql`
  query GetOrganizationByNameorDomain($name: String!) {
    GetOrganizationByNameorDomain(name: $name) {
      name
    }
  }
`

const GET_ORG_BY_DOMAIN = gql`
query GetOrganizationByDomain($domain: String!) {
  GetOrganizationByDomain(domain: $domain) {
    _id
    domain
    name

  }
}
`

const GET_ALL_ORGANIZATIONS = gql`
query Organizations($page: Int!, $perPage: Int!, $isTrash: Boolean, $search: String) {
  Organizations(page: $page, perPage: $perPage, isTrash: $isTrash, search: $search) {
      organizations {
      _id
      domain
      name
      description
    }
    totalCount
  }
}
`

const GET_ORGANIZATION_BY_ID = gql`
query Organization($organizationId: ID!) {
  Organization(id: $organizationId) {
    _id
    description
    domain
    name
  }
}
`

export { GetOwnerOrganization, CheckOrganization, GetLiveOrganization, GET_ORG_BY_DOMAIN, GET_ALL_ORGANIZATIONS, GET_ORGANIZATION_BY_ID }
