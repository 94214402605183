// src/components/filter.
import React, { useEffect, useMemo, useRef, useState } from "react"
import PropTypes from "prop-types"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "../../components/Common/TableContainer"
import useCustomQuery from "../../graphql/hooks/useCustomQuery"
import PuffLoading from "components/Loading/PuffLoading"
import { Button, Col, Row } from "reactstrap"
import Pagination from "../../components/pagination"
import { Link } from "react-router-dom"
import { confirmAlert } from "react-confirm-alert"
import useCustomMutation from "../../graphql/hooks/useCustomMutation"
import { toast } from "react-toastify"
import { DELETE_USER_MUTATION } from "../../graphql/mutations/user"
import { GET_ALL_USERS, GET_ALL_USERS_OF_ORGANIZATION } from "graphql/queries/user"
import { GET_ROLES_OF_ORGANIZATION } from "../../graphql/queries/role"
import Multiselect from 'multiselect-react-dropdown';
import { UPDATE_USER_ROLE_BY_ID } from "../../graphql/mutations/role"
import { useLazyQuery } from "@apollo/client"
import PaginatedTable from "components/Table/PaginatedTable"
import OvalLoading from "components/Loading/OvalLoading"

function User() {

  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(25)
  const [totalCount, setTotalCount] = useState(0)
  const [searchTerm, setSearchTerm] = useState("")
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("")


  var [DeleteUser, { loading, error }] = useCustomMutation(
    DELETE_USER_MUTATION,
    {
      refetchQueries: [{
        query: GET_ALL_USERS_OF_ORGANIZATION, variables: {
          page: page,
          perPage: perPage,
          isTrash: false,
          search: searchTerm?.trim(),
        }
      }],
    }
  )

  const [orgRoles, setOrgRoles] = useState([])

  var { loading, error, data: orgRolesData, execute } = useCustomQuery(GET_ROLES_OF_ORGANIZATION)

  useEffect(() => {
    if (orgRolesData) {
      setOrgRoles(orgRolesData?.GetRolesOfOrganization?.filter(role => role.isDisabled === false) || [])
    }
  }, [orgRolesData])

  const loggedInUserId = JSON.parse(localStorage.getItem("authUser"))._id



  // Server side searching
  const [search, { loading: searchLoading, __, data }] =
    useLazyQuery(GET_ALL_USERS_OF_ORGANIZATION)

  // First time load all data with pagination
  useEffect(() => {
    if (debouncedSearchTerm.trim() === "") {
      search({
        variables: {
          page: page,
          perPage: perPage,
          isTrash: false,
          search: searchTerm?.trim(),
        },
      })
    }
  }, [debouncedSearchTerm, page, perPage])

  // set debounce value with timer delay
  useEffect(() => {
    const delay = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm)
    }, 1000)

    return () => {
      clearTimeout(delay)
    }
  }, [searchTerm])

  // fech if search term ha any value
  useEffect(() => {
    if (debouncedSearchTerm.trim() !== "") {
      search({
        variables: {
          page: page,
          perPage: perPage,
          isTrash: false,
          search: searchTerm?.trim(),
        },
      })
    }
  }, [debouncedSearchTerm, page, perPage])


  const deleteUser = id => {
    confirmAlert({
      title: "Confirm",
      message: "Are you sure you want to delete this USER?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            DeleteUser("DeleteUser", {
              deleteUserId: id,
              deletedBy: loggedInUserId
            })
              .then(user => {
                toast.success("User Deleted successfully!")
              })
              .catch(err => {
                console.error("Error deleting user:", err)
              })
          },
        },
        {
          label: "No",
          onClick: () => { },
        },
      ],
    })
  }


  const [userData, setUserData] = useState([])

  var [UpdateUserRoleById, { loading, error }] = useCustomMutation(
    UPDATE_USER_ROLE_BY_ID,
    {
      refetchQueries: [
        { query: GET_ROLES_OF_ORGANIZATION },
        {
          query: GET_ALL_USERS_OF_ORGANIZATION, variables: {
            page: page,
            perPage: perPage,
            isTrash: false,
            search: searchTerm?.trim(),
          }
        }
      ],
    }
  )

  if (JSON.parse(localStorage.getItem("authUser"))?.roleType?.map(role => role?.name)?.includes("Super Admin")) {
    var { loading, error, data: users, execute } = useCustomQuery(GET_ALL_USERS)
  } else {
    var { loading, error, data: users, execute } = useCustomQuery(GET_ALL_USERS_OF_ORGANIZATION, {
      variables: {
        page: page,
        perPage: perPage,
        isTrash: false,
        search: searchTerm?.trim(),
      },
    })
  }

  useEffect(() => {
    if (users) {
      setUserData(users?.Users || users?.GetAllUsersOfOrganization?.users || [])
      setTotalCount(users?.GetAllUsersOfOrganization?.totalCount)
    }
  }, [users])

  if (error) {
    console.log(error)
  }


  const onSelectRoleCheckBox = (roles) => {
    const userId = roles?.find(role => role.userId && role.userId !== null)?.userId
    const roleIds = roles?.map(role => role.id);
    UpdateUserRoleById("UpdateUserRoleById", { updateUserRoleId: userId, input: { roles: roleIds } })
      .then(roles => {
        if (roles) {
          toast.success("Roles Updated Successfully !")
          if (roles && roles?.data && roles.data.length) {
            setOrgRoles(roles.data.Roles)
          }
        }
      })
      .catch(err => {
        console.error(err)
      })
  }
  const onRemoveRoleCheckBox = (roles, deletedRole) => {
    const userId = roles?.find(role => role.userId && role.userId !== null)?.userId
    const roleIds = roles?.map(role => role.id);
    UpdateUserRoleById("UpdateUserRoleById", { updateUserRoleId: userId, input: { roles: roleIds } })
      .then(roles => {
        if (roles) {
          toast.success("Roles Updated Successfully !")
          if (roles && roles?.data && roles.data.length) {
            setOrgRoles(roles.data.Roles)
          }
        }
      })
      .catch(err => {
        console.error(err)
      })
  }

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "fullName",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Roles",
        accessor: "roles",
        Cell: (row) => {
          return (
            <>
              <Multiselect
                displayValue="role"
                onKeyPressFn={function noRefCheck() { }}
                onRemove={onRemoveRoleCheckBox}
                onSearch={function noRefCheck() { }}
                onSelect={onSelectRoleCheckBox}
                placeholder="Select roles for user"
                closeIcon="cancel"
                options={orgRoles?.map(role => {
                  return {
                    id: role._id,
                    role: role.name,
                    userId: row.row.original._id,
                  }
                })}
                disablePreSelectedValues={orgRoles?.filter(role => {
                  const userRoles = row.value?.map(r => r.roleId)
                  return userRoles?.includes(role._id)
                }).length === 1 ? true : false}
                selectedValues={orgRoles?.filter(role => {
                  const userRoles = row.value?.map(r => r.roleId)
                  return userRoles?.includes(role._id)
                }).map(role => {
                  return {
                    id: role._id,
                    role: role.name,
                    userId: row.row.original._id,
                  }
                })}
                showCheckbox
                style={{
                  multiselectContainer: {
                    maxWidth: 220
                  },
                  searchBox: {
                    border: 'none',
                    'border-bottom': '1px solid blue',
                    'border-radius': '0px'
                  }
                }}
              />

            </>
          )
        },
      },


      {
        Header: "Action",
        accessor: "_id",
        Cell: ({ value }) => {
          return (
            <div className="d-flex gap-3">
              <Link className="text-info" onClick={() => viewUser(value)}>
                <i className="mdi mdi-eye font-size-18"></i>
              </Link>
              <Link
                className="text-danger"
                to="#"
                onClick={() => deleteUser(value)}>
                <i className="mdi mdi-delete font-size-18"></i>
              </Link>
            </div>
          )
        },
      },
    ],
    [userData, orgRoles]
  )

  document.title = "Users"

  return (
    <div className="page-content">
      {loading && <PuffLoading />}
      <div className="container-fluid">
        <Breadcrumbs title="Tables" breadcrumbItem="Users" />
        <Link to="/users/create">
          <Button
            color="primary"
            className="btn btn-primary waves-effect waves-light"
            style={{ position: "absolute", left: "auto", right: 25 }}
          >
            Create User
          </Button>
        </Link>

        <div className={totalCount <= perPage ? "mt-5" : "mt-5"}>
          <Row>
            <Col md={4} className="d-flex align-items-center">
              <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
                <div className="position-relative">
                  <label htmlFor="search-bar-0" className="search-label">
                    <span id="search-bar-0-label" className="sr-only">
                      Search this table
                    </span>
                    <input
                      onChange={e => {
                        setSearchTerm(e.target.value)
                      }}
                      id="search-bar-0"
                      type="text"
                      className="form-control"
                      placeholder={`Search here`}
                      value={searchTerm}
                    />
                  </label>
                  <i className="bx bx-search-alt search-icon"></i>
                </div>
              </div>
            </Col>
            <Col md={8}>
              <Pagination
                totalRows={totalCount}
                pageChangeHandler={setPage}
                rowsPerPage={perPage}
                setRowsPerPage={setPerPage}
                currentPage={page}
              />
            </Col>
          </Row>


          <div className="position-relative">
            {searchLoading && <OvalLoading />}
            <PaginatedTable
              columns={columns}
              data={userData || []}
              isLoading={searchLoading}
            />
          </div>
          <Pagination
            totalRows={totalCount}
            pageChangeHandler={setPage}
            rowsPerPage={perPage}
            setRowsPerPage={setPerPage}
            currentPage={page}
          />

        </div>

      </div>
    </div>
  )
}
User.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default User
