import { gql } from '@apollo/client';

const GET_ALL_USERS = gql`
  query Users {
    Users {
      _id
      email
      fullName
      roles {
        roleId
      }
      roleType {
        _id
        name
      }
      type
    }
  }
`

const GET_ALL_CUSTOMERS = gql`
  query GetAllCustomers($page: Int!, $perPage: Int!, $isTrash: Boolean, $search: String) {
    GetAllCustomers(page: $page, perPage: $perPage, isTrash: $isTrash, search: $search) {
      totalCount
      users {
        _id
        email
        fullName
        phoneNum
        firstOrderAt
        loyaltyPoints
        socialPlatform
        platform
        type
        blacklistedAt
        totalOrders
        totalRevenue
      }
    }
  }
`

const GET_ALL_GUESTS = gql`
  query GetAllGuests($page: Int!, $perPage: Int!, $isTrash: Boolean, $search: String) {
    GetAllGuests(page: $page, perPage: $perPage, isTrash: $isTrash, search: $search) {
      totalCount
      users {
        _id
        email
        fullName
        phoneNum
        firstOrderAt
        loyaltyPoints
        socialPlatform
        platform
        type
        blacklistedAt
        totalOrders
        totalRevenue
      }
    }
  }
`

const GET_ALL_USERS_OF_ORGANIZATION = gql`
  query GetAllUsersOfOrganization($page: Int!, $perPage: Int!, $isTrash: Boolean, $search: String) {
    GetAllUsersOfOrganization(page: $page, perPage: $perPage, isTrash: $isTrash, search: $search) {
      users {
        email
        _id
        firstOrderAt
        fullName
        phoneNum
        socialPlatform
        totalOrders
        totalRevenue
        loyaltyPoints
        roles {
          roleId
        }
        roleType {
          _id
          name
        }
        type
      }
      totalCount
    }
  }
`

const GET_USER = gql`
  query GetUserById($getUserByIdId: ID!) {
    GetUserById(id: $getUserByIdId) {
      _id
      email
      fullName
      phoneNum
      firstOrderAt
      loyaltyPoints
      socialPlatform
      image{
        image
        moduleType
      }
      platform
      blacklistedAt
      roles {
        roleId
      }
      roleType {
        _id
        name
      }
      type
    }
  }
`

const GET_USER_OF_ALL_ORGANIZATIONS = gql`
query GetAllUsersOfAllOrganizations($page: Int!, $perPage: Int!, $isTrash: Boolean, $search: String) {
  GetAllUsersOfAllOrganizations(page: $page, perPage: $perPage, isTrash: $isTrash, search: $search) {
    totalCount
    users {
      _id
      email
      fullName
      isOwner
      loyaltyPoints
      isVerified
      organization {
        _id
        name
      }
      otpCode
      phoneNum
      roleType {
        _id
        name
      }
      roles {
        isDisabled
        roleId
      }
      type
      totalRevenue
      totalOrders
    }
  }
}
`

export { GET_USER, GET_ALL_USERS, GET_ALL_CUSTOMERS, GET_ALL_USERS_OF_ORGANIZATION, GET_ALL_GUESTS, GET_USER_OF_ALL_ORGANIZATIONS }
