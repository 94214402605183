// src/components/filter.
import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  Input,
  InputGroup,
  Label,
  Row,
} from "reactstrap"
import { toast } from "react-toastify"
import {
  CREATE_ROLE_MUTATION,
  UPDATE_ROLE_MUTATION,
} from "../../graphql/mutations/role"
import useCustomMutation from "../../graphql/hooks/useCustomMutation"
import { GET_ROLE } from "../../graphql/queries/role"
import { useNavigate, useParams } from "react-router-dom"
import useCustomQuery from "../../graphql/hooks/useCustomQuery"
import PuffLoading from "components/Loading/PuffLoading"

function CreateRole({ title, isEdit }) {
  const [name, setName] = useState("")
  const [isActive, setIsActive] = useState(true)
  const navigate = useNavigate()

  document.title = title
  const params = useParams()
  if (params.roleId && isEdit) {
    var { loading, error, data, execute } = useCustomQuery(GET_ROLE, {
      variables: { roleId: params.roleId },
    })
  }

  useEffect(() => {
    if (params.roleId && isEdit && data) {
      setName(data?.Role?.name)
      setIsActive(data?.Role?.isActive)
    }
  }, [data])

  var [CreateRole, { loading, error }] = useCustomMutation(CREATE_ROLE_MUTATION)
  var [UpdateRole, { loading, error }] = useCustomMutation(UPDATE_ROLE_MUTATION)

  const handleStatusChange = e => {
    setIsActive(!e.target.checked)
  }

  const handleFormSubmit = e => {
    e.preventDefault()
    if (!name) {
      toast.error("Role name is required")
      return
    }
    const id = JSON.parse(localStorage.getItem("authUser"))._id
    const input = {
      name,
      isDisabled: isActive == true ? false : true,
      updatedBy: id,
    }
    if (isEdit) {
      UpdateRole("UpdateRole", { updateRoleId: params.roleId, input })
        .then(role => {
          if (role) {
            toast.success("Role Updated Successfully !");
            setTimeout(() => {
              navigate('/roles')
            }, 1000);
          }
        })
        .catch(err => {
          console.log(err)
        })
    } else {
      input.createdBy = id
      CreateRole("CreateRole", { input })
        .then(role => {
          if (role) {
            toast.success("Role Created Successfully !")
            setTimeout(() => {
              navigate('/roles')
            }, 1000);
          }
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
  return (
    <div className="page-content">
      {loading && <PuffLoading />}
      <div className="container-fluid">
        <Breadcrumbs title="Branch" breadcrumbItem={title} />
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <CardTitle className="h5 mb-4">Role</CardTitle>

                <Form className="row gy-2 gx-3 align-items-center">
                  <div className="col-sm-auto">
                    <Label
                      className="visually-hidden"
                      htmlFor="autoSizingInput"
                    >
                      Role Name
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="autoSizingInput"
                      placeholder="Enter role name"
                      value={name}
                      onChange={e => setName(e.target.value)}
                    />
                  </div>

                  <div className="col-sm-auto">
                    <div className="form-check form-switch form-switch-md">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="role-status"
                        onClick={handleStatusChange}
                        checked={isActive}
                      />
                      <label className="form-check-label" htmlFor="role-status">
                        Status
                      </label>
                    </div>
                  </div>
                  <div className="col-sm-auto">
                    <button
                      type="submit"
                      onClick={handleFormSubmit}
                      className="btn btn-primary w-md"
                    >
                      {isEdit ? "Update Role" : "Create Role"}
                    </button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}
CreateRole.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default CreateRole
