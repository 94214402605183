import React, { useState, useEffect } from "react"
import styles from "./styles.module.css"
import { Button } from "reactstrap"

const Pagination = ({
  pageChangeHandler,
  totalRows,
  rowsPerPage,
  setRowsPerPage,
  currentPage,
}) => {
  // Calculating max number of pages
  const noOfPages = Math.ceil(totalRows / rowsPerPage)

  // Creating an array with length equal to no.of pages
  const pagesArr = [...new Array(noOfPages)]

  // State variable to hold the current page. This value is
  // passed to the callback provided by the parent
  // const [currentPage, setCurrentPage] = useState(1);

  // Navigation arrows enable/disable state
  const [canGoBack, setCanGoBack] = useState(false)
  const [canGoNext, setCanGoNext] = useState(true)

  // These variables give the first and last record/row number
  // with respect to the current page
  const [pageFirstRecord, setPageFirstRecord] = useState(1)
  const [pageLastRecord, setPageLastRecord] = useState(rowsPerPage)

  // Onclick handlers for the butons
  const onNextPage = () => pageChangeHandler(currentPage + 1)
  const onPrevPage = () => pageChangeHandler(currentPage - 1)
  const onPageSelect = pageNo => pageChangeHandler(pageNo)

  // Disable previous and next buttons in the first and last page
  // respectively
  useEffect(() => {
    if (noOfPages === currentPage) {
      setCanGoNext(false)
    } else {
      setCanGoNext(true)
    }
    if (currentPage === 1) {
      setCanGoBack(false)
    } else {
      setCanGoBack(true)
    }
  }, [noOfPages, currentPage])

  // To set the starting index of the page
  useEffect(() => {
    const skipFactor = (currentPage - 1) * rowsPerPage
    setPageFirstRecord(skipFactor + 1)
  }, [currentPage])

  // To set the last index of the page
  useEffect(() => {
    const count = pageFirstRecord + rowsPerPage
    setPageLastRecord(count > totalRows ? totalRows : count - 1)
  }, [pageFirstRecord, rowsPerPage, totalRows])

  const maxVisibleButtons = 5
  const renderPaginationButtons = () => {
    const totalButtons = pagesArr.length
    const currentPageIndex = currentPage - 1

    if (totalButtons <= maxVisibleButtons) {
      // Display all buttons if the total is less than or equal to the max visible buttons
      return pagesArr.map((num, index) => (
        <Button
          key={index}
          onClick={() => onPageSelect(index + 1)}
          className={`${styles.pageBtn} ${
            index + 1 === currentPage ? "bg-white text-dark" : ""
          }`}
        >
          {index + 1}
        </Button>
      ))
    } else {
      const buttonsToShow = []
      let startButtonIndex, endButtonIndex

      if (currentPageIndex < Math.ceil(maxVisibleButtons / 2)) {
        // If the current page is near the beginning, show the first set of buttons
        startButtonIndex = 0
        endButtonIndex = maxVisibleButtons - 2
      } else if (
        currentPageIndex >=
        totalButtons - Math.floor(maxVisibleButtons / 2)
      ) {
        // If the current page is near the end, show the last set of buttons
        startButtonIndex = totalButtons - maxVisibleButtons + 2
        endButtonIndex = totalButtons - 1

        // Include the first button if it's not already visible
        if (startButtonIndex > 1) {
          buttonsToShow.push(
            <Button
              key={0}
              onClick={() => onPageSelect(1)}
              className={`${styles.pageBtn}`}
            >
              1
            </Button>
          )

          // Show ellipsis if the first button is not adjacent to the ellipsis
          if (startButtonIndex > 2) {
            buttonsToShow.push(
              <Button key={1} className={`${styles.pageBtn}`} disabled>
                ...
              </Button>
            )
          }
        }
      } else {
        // Otherwise, show a portion of buttons around the current page with ellipsis
        startButtonIndex =
          currentPageIndex - Math.floor(maxVisibleButtons / 2) + 1
        endButtonIndex =
          currentPageIndex + Math.floor(maxVisibleButtons / 2) - 1

        // Include the first button if the current page is not in the first set of visible buttons
        buttonsToShow.push(
          <Button
            key={0}
            onClick={() => onPageSelect(1)}
            className={`${styles.pageBtn}`}
          >
            1
          </Button>
        )
        buttonsToShow.push(
          <Button key={1} className={`${styles.pageBtn}`} disabled>
            ...
          </Button>
        )
      }

      for (let i = startButtonIndex; i <= endButtonIndex; i++) {
        buttonsToShow.push(
          <Button
            key={i + 1}
            onClick={() => onPageSelect(i + 1)}
            className={`${styles.pageBtn} ${
              i + 1 === currentPage ? "bg-white text-dark" : ""
            }`}
          >
            {i + 1}
          </Button>
        )
      }

      return buttonsToShow
    }
  }

  return (
    <>
      {noOfPages >= 1 ? (
        <>
          <div className="d-flex align-items-center justify-content-end">
            {/* show rows out of total  */}
            <div className="me-2 fw-bolder font-size-14">
              Showing {pageFirstRecord} - {pageLastRecord} of {totalRows}
            </div>
            <select
              className="form-select mb-1"
              style={{ maxWidth: 70, width: "100%" }}
              onChange={e => setRowsPerPage(parseInt(e.target.value))}
            >
              <option defaultValue="5" selected={rowsPerPage === 5}>
                5
              </option>
              <option defaultValue="10" selected={rowsPerPage === 10}>
                10
              </option>
              <option defaultValue="25" selected={rowsPerPage === 25}>
                25
              </option>
              <option defaultValue="50" selected={rowsPerPage === 50}>
                50
              </option>
              <option defaultValue="100" selected={rowsPerPage === 100}>
                100
              </option>
            </select>
            <div className={styles.pagination + " me-0"}>
              <div className={styles.pagebuttons + ' pagination-buttons'}>
                <Button
                  color="primary"
                  onClick={onPrevPage}
                  disabled={!canGoBack}
                >
                  &#8249;
                </Button>
                {renderPaginationButtons()}
                <Button
                  color="primary"
                  onClick={onNextPage}
                  disabled={!canGoNext}
                >
                  &#8250;
                </Button>
              </div>
            </div>
          </div>

          {/* <div className={styles.pageInfo}>
            Showing {pageFirstRecord} - {pageLastRecord} of {totalRows}
          </div> */}
        </>
      ) : null}
    </>
  )
}

export default Pagination
