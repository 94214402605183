import { gql } from '@apollo/client';

const GET_ROLES = gql`
  query Roles {
    Roles {
      name
      _id
      isDisabled
      createdAt
      updatedAt
    }
  }
`
const GET_ROLES_OF_ORGANIZATION = gql`
  query GetRolesOfOrganization {
    GetRolesOfOrganization {
      name
      _id
      isDisabled
    }
  }
`
const GET_ROLE = gql`
  query Role($roleId: ID!) {
    Role(id: $roleId) {
      name
      _id
      isDisabled
    }
  }
`

export { GET_ROLE, GET_ROLES, GET_ROLES_OF_ORGANIZATION }
