import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, CardTitle, Container } from "reactstrap"

import useCustomQuery from "../../graphql/hooks/useCustomQuery"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

import { useSelector, useDispatch } from "react-redux"

import { useLazyQuery } from "@apollo/client"


const Dashboard = props => {
  const { user } = useSelector(state => ({
    error: state.Login.error,
  }))



  //meta title
  document.title = `Dashboard | ${process.env.REACT_APP_NAME}`

  const labels = ["Delivery", "Takeaway"]

  return (
    <React.Fragment>
      {/* subscribe ModalHeader */}
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(Dashboard)
