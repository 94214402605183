import { useQuery } from "@apollo/client"

const useCustomQuery = (query, options = {}) => {
  const { loading, error, data, ...rest } = useQuery(query, options)

  return {
    loading,
    error,
    data,
    execute: newOptions => {
      return useQuery(query, { ...options, ...newOptions })
    },
    ...rest,
  }
}

export default useCustomQuery
