import { gql } from '@apollo/client';

const CREATE_ROLE_MUTATION = gql`
  mutation CreateRole($input: CreateRoleInput!) {
    CreateRole(input: $input) {
      _id
    }
  }
`
const UPDATE_ROLE_MUTATION = gql`
  mutation UpdateRole($updateRoleId: ID!, $input: UpdateRoleInput!) {
    UpdateRole(id: $updateRoleId, input: $input) {
      _id
    }
  }
`
const UPDATE_USER_ROLE_BY_ID = gql`
  mutation UpdateUserRoleById($updateUserRoleId: ID!, $input: UpdateUserRoleInput!) {
    UpdateUserRoleById(id: $updateUserRoleId, input: $input) {
      _id
    }
  }
`

const DELETE_ROLE_MUTATION = gql`
  mutation DeleteRole($deleteRoleId: ID!) {
    DeleteRole(id: $deleteRoleId) {
      _id
    }
  }
`

export { CREATE_ROLE_MUTATION, UPDATE_ROLE_MUTATION, UPDATE_USER_ROLE_BY_ID, DELETE_ROLE_MUTATION }
