import React, { useEffect, useMemo, useState } from 'react';
import { useSortBy, useTable } from "react-table"
import CancelIcon from '@mui/icons-material/Cancel';
import { Label, Table } from "reactstrap"
import { Stack } from '@mui/system';
import { toast } from 'react-toastify';
import ShipmentLoader from 'components/Common/ShipmentLoader';
import { PARTNERS } from 'utils/constants';
import CheckIcon from '../../assets/images/check.svg'

const ShipmentTable = ({ oldPayloadArray, shipment, setShipment, shipmentIndex, shipmentValue, columns, data, selectedId, orderData, setOrderData, setSelectedId, paylodArray, setConfirmShipmentIds, setFinalShipmentPayloadData, setPlayloadArray, getModelConfirmation, handleDelete, isDelete, getModelStatus, isAllowHideSection, onPrintLabelClick, isPrintLabelLoading, isShowPrintLabel, hasLiveShipmentId, shipmentDeliveryPartner, getPackageModel, getActiveShipmentDetail, isLockRecord }) => {
  const [shipmentData, setShipmentData] = useState([]);

  // console.log('dm shipmentIndex', shipmentIndex)
  // console.log('dm shipmentData', shipmentData)
  // console.log('dm payload array', paylodArray)

  const [activeRecord, setActiveRecordDetail] = useState(null);
  const [activeRecordIndex, setActiveRecordIndex] = useState(null);

  const [selectedPartner, setSelectedPartner] = useState(null);

  // const response = [];
  const columnData = useMemo(() => columns, [columns])

  // const rowData = useMemo(() => shipmentData, [shipmentData])

  // console.log('dm oldPayloadArray inside indexwise =>', oldPayloadArray, shipmentIndex)

  useEffect(() => {
    if (paylodArray.length) {
      if (paylodArray[shipmentIndex]) {
        setShipmentData(paylodArray[shipmentIndex])
      }
    }
  }, [paylodArray])

  useEffect(() => {
    if (oldPayloadArray?.length) {
      // setShipmentData(oldPayloadArray)
      if (oldPayloadArray[shipmentIndex]) {
        setShipmentData(oldPayloadArray[shipmentIndex])
      }
      else {
        setShipmentData([...shipmentData])
      }
    }
  }, [oldPayloadArray])


  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns: columnData,
        data: shipmentData // rowData,
        // manualPagination,
      },
      useSortBy
    )
  // console.log('rows are::',rows);
  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""
  }
  const cancelOnClick = (value) => {
    setPlayloadArray(prevSelectedId => prevSelectedId.filter((_, i) => i !== value));
    setShipment(prevSelectedId => prevSelectedId.filter((_, i) => i !== value)); // comment by dm
    // setShipment(prevSelectedId => prevSelectedId.map((o, i) => i === value ? null : o)); // solution
    setOrderData((prev) => {
      return {
        ...prev,
        items: [...prev.items, ...shipmentData]
      }
    })
    setSelectedId([]);
    setShipmentData([]);

    setConfirmShipmentIds(prev => {
      prev.splice(shipmentIndex, 1);
      return prev;
    })

    setFinalShipmentPayloadData(prev => {
      prev.splice(shipmentIndex, 1);
      return prev;
    })

  }

  const handleMove = (index) => {
    if (selectedPartner === null || selectedPartner === 1) {
      toast.warn("Please Select a Delivery Partner")
    } else {
      const filterData = orderData.filter(item => selectedId.includes(item.product_id));
      const removeData = orderData.filter(item => !selectedId.includes(item.product_id));
      setOrderData((prev) => {
        return {
          ...prev,
          items: removeData
        }
      })
      // response.push()
      setPlayloadArray(preArray => preArray.map((subArray, i) => {
        if (i === shipmentIndex) {
          // return [...subArray, ...filterData]
          return [...subArray, ...filterData?.map(o => ({ ...o, isShipped: false }))]  // added new 
        }
        return subArray;
      }))

      setShipmentData((prev) => {
        return [...prev, ...filterData]
      });

      // setShipmentData(filterData);
      setSelectedId([]);
    }
  }

  const cancleSingleProduct = (value, index) => {
    if (value) {
      setPlayloadArray(prevArray =>
        prevArray.map((subArray, i) => {
          if (i === index) {
            return subArray.filter(obj => obj?.product_id !== value?.values?.product_id);
          }
          return subArray;
        })
      )

      let find = shipmentData.find((item) => item?.product_id === value?.values?.product_id)
      setOrderData((prev) => {
        return {
          items: [...prev.items, find]
        }
      })
      setShipmentData(shipmentData.filter((item) => value?.values?.product_id !== item?.product_id))

      setActiveRecordDetail(null);
      setActiveRecordIndex(null);
      handleDelete()
    }
  }

  const handleModelCofirmation = (value, index) => {
    setActiveRecordDetail(value);
    setActiveRecordIndex(index)
    getModelConfirmation()
  }

  useEffect(() => {
    if (isDelete) {
      cancleSingleProduct(activeRecord, activeRecordIndex)
    }
  }, [isDelete])

  useEffect(() => {
    if (!getModelStatus()) {
      setActiveRecordDetail(null);
      setActiveRecordIndex(null);
    }
  }, [getModelStatus])

  const handleSelectedPartner = (e) => {
    setSelectedPartner(e.target.value)
  }

  const handlePackageModel = (shipmentRow, shipmentValue) => {
    getPackageModel()
    getActiveShipmentDetail(shipmentRow, selectedPartner, shipmentValue, shipmentIndex)
  }

  return (
    <>
      {isPrintLabelLoading && <ShipmentLoader messageText="Fetching Print Lables..." />}
      <div
        className="table-responsive react-table mt-1"
        style={{ maxHeight: "650px" }}
      >
        <Table {...getTableProps()} className="custom-header-css">
          <thead className="table-light table-head">
            {headerGroups.map((headerGroup, i) => (
              <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                <Stack display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                  <h5 style={{ fontSize: "14px", margin: 0 }}>
                    {/* {`Shipment #${shipmentValue}`} */}
                    {hasLiveShipmentId ? `#${shipmentIndex + 1} Shipment Id : ${shipmentValue}` : `#${shipmentValue}`}
                  </h5>

                  {isAllowHideSection ?
                    <div className='d-flex justify-content-between align-items-center'>
                      <h5 style={{ fontSize: "14px", margin: 0 }} className="me-3">Delivery Partner : {shipmentDeliveryPartner}</h5>

                      {isShowPrintLabel ?
                        <button
                          type="submit"
                          className="btn btn-primary waves-effect waves-light"
                          onClick={() => onPrintLabelClick(shipmentValue, shipmentDeliveryPartner)}
                          disabled={isPrintLabelLoading}
                        >
                          Print Labels
                        </button> :
                        <h5 style={{ fontSize: "14px", margin: 0 }} className="me-3">Print Label Status : Pending</h5>
                      }
                    </div>
                    :
                    <Stack display={'flex'} flexDirection={'row'} gap={1} alignItems={'center'}>
                      <Label
                        className="form-label mt-2"
                        style={{ fontSize: "14px" }}
                      >
                        Delivery Partner
                      </Label>
                      <select className='form-select' style={{ width: '200px' }} onChange={(e) => handleSelectedPartner(e)}>
                        {PARTNERS?.map((item, i) => (
                          <option
                            key={i}
                            value={item.value}
                          >
                            {item.label}
                          </option>
                        ))}
                      </select>
                      <button
                        type="submit"
                        className="ms-2 btn btn-primary waves-effect waves-light btn btn-primary"
                        // onClick={handleMove.bind(null, shipmentIndex)}
                        onClick={() => handleMove()}
                        // style={{ margin: "0 8px" }}
                        disabled={isLockRecord}
                      >
                        Add Selected Products
                      </button>


                      {isLockRecord ? <p className='mb-0' style={{ fontSize: '14px', fontWeight: '500' }}> <img src={CheckIcon} /> Confirmed</p> :
                        <button
                          className="ms-2 btn btn-primary waves-effect waves-light btn btn-primary"
                          onClick={() => handlePackageModel(shipmentData, shipmentValue)}
                          disabled={shipmentData?.length === 0}
                        >
                          Confirm
                        </button>
                      }

                      <button
                        className="ms-2 btn btn-primary waves-effect waves-light btn btn-primary"
                        style={{ background: "#dc3545", borderColor: "#dc3545" }}
                        onClick={() => cancelOnClick(shipmentIndex)}
                      >
                        Cancel
                      </button>

                    </Stack>
                  }

                </Stack>
              </tr>
            ))}
          </thead>
          <tbody>
            <tr>
              <td colSpan={3}>
                <Table
                  {...getTableProps()}
                  className="custom-header-css add-move-shipment-table"
                >
                  <thead className="table-light table-head">
                    {headerGroups.map((headerGroup, i) => (
                      <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                        {rows.length ? <th></th> : null}
                        {headerGroup.headers.map((column, i) => {
                          return (
                            <th key={i} {...column.getHeaderProps()}>
                              <div
                                style={{ minHeight: "unset" }}
                                {...(column.Header == "Code" ||
                                  column.Header == "Address" ||
                                  column.Header == "Action"
                                  ? null
                                  : column.getSortByToggleProps())}
                              >
                                {column.render("Header")?.toUpperCase()}
                                {generateSortingIndicator(column)}
                              </div>
                            </th>
                          )
                        })}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {rows && rows?.length > 0 ? (
                      rows.map((row, i) => {
                        prepareRow(row)
                        return (
                          <tr key={i} {...row.getRowProps()}>
                            {isAllowHideSection || isLockRecord ? <td></td> :
                              <td>
                                <CancelIcon
                                  onClick={() => {
                                    // cancleSingleProduct(row, shipmentIndex)
                                    handleModelCofirmation(row, shipmentIndex)
                                  }}
                                  style={{ cursor: "pointer" }}
                                />
                              </td>
                            }
                            {row.cells.map((cell, i) => {
                              return (
                                <td
                                  style={{ fontSize: 12 }}
                                  key={i}
                                  {...cell.getCellProps()}
                                >
                                  {cell.render("Cell")}
                                </td>
                              )
                            })}
                          </tr>
                        )
                      })
                    ) : (
                      <tr>
                        <td colSpan="9" className="no-found-data">
                          No Data Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </td>
            </tr>
          </tbody>
        </Table>
      </div >
    </>
  )
};

export default ShipmentTable;
