// src/components/filter.
import React, { useEffect, useMemo, useRef, useState } from "react"
import PropTypes from "prop-types"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "../../components/Common/TableContainer"
// import useCustomQuery from "../../graphql/hooks/useCustomQuery"
import PuffLoading from "components/Loading/PuffLoading"
import { Button, Col, Row } from "reactstrap"
import Pagination from "../../components/pagination"
import { Link } from "react-router-dom"
import { confirmAlert } from "react-confirm-alert"
// import useCustomMutation from "../../graphql/hooks/useCustomMutation"
import { toast } from "react-toastify"
import { DELETE_USER_MUTATION } from "../../graphql/mutations/user"
// import { GET_ALL_USERS } from "graphql/queries/user"
// import { GET_ROLES_OF_ORGANIZATION } from "../../graphql/queries/role"
import Multiselect from 'multiselect-react-dropdown';
// import { UPDATE_USER_ROLE_BY_ID } from "../../graphql/mutations/role"
import { useLazyQuery } from "@apollo/client"
import PaginatedTable from "components/Table/PaginatedTable"
import OvalLoading from "components/Loading/OvalLoading"
import { GET_ALL_ORGANIZATIONS } from "../../graphql/queries/organization"
import { DELETE_ORGANIZATION } from "../../graphql/mutations/organization"

function Organizations() {

  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(25)
  const [totalCount, setTotalCount] = useState(0)
  const [searchTerm, setSearchTerm] = useState("")
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("")


  var [DeleteUser, { loading, error }] = useCustomMutation(
    DELETE_ORGANIZATION,
    {
      refetchQueries: [{
        query: GET_ALL_ORGANIZATIONS, variables: {
          page: page,
          perPage: perPage,
          isTrash: false,
          search: searchTerm?.trim(),
        }
      }],
    }
  )

  const [orgRoles, setOrgRoles] = useState([])

  var { loading, error, data: orgRolesData, execute } = useCustomQuery(GET_ROLES_OF_ORGANIZATION)

  useEffect(() => {
    if (orgRolesData) {
      setOrgRoles(orgRolesData?.GetRolesOfOrganization?.filter(role => role.isDisabled === false) || [])
    }
  }, [orgRolesData])

  const loggedInUserId = JSON.parse(localStorage.getItem("authUser"))._id



  // Server side searching
  const [search, { loading: searchLoading, __, data }] =
    useLazyQuery(GET_ALL_ORGANIZATIONS)

  // First time load all data with pagination
  useEffect(() => {
    if (debouncedSearchTerm.trim() === "") {
      search({
        variables: {
          page: page,
          perPage: perPage,
          isTrash: false,
          search: searchTerm?.trim(),
        },
      })
    }
  }, [debouncedSearchTerm, page, perPage])

  // set debounce value with timer delay
  useEffect(() => {
    const delay = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm)
    }, 1000)

    return () => {
      clearTimeout(delay)
    }
  }, [searchTerm])

  // fech if search term ha any value
  useEffect(() => {
    if (debouncedSearchTerm.trim() !== "") {
      search({
        variables: {
          page: page,
          perPage: perPage,
          isTrash: false,
          search: searchTerm?.trim(),
        },
      })
    }
  }, [debouncedSearchTerm, page, perPage])


  const deleteUser = id => {
    confirmAlert({
      title: "Confirm",
      message: "Are you sure you want to delete this Organization?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            DeleteUser("DeleteUser", {
              deleteOrganizationId: id,
            })
              .then(user => {
                toast.success("Organization Deleted successfully!")
              })
              .catch(err => {
                console.error("Error deleting Organization:", err)
              })
          },
        },
        {
          label: "No",
          onClick: () => { },
        },
      ],
    })
  }


  const [userData, setUserData] = useState([])

  var [UpdateUserRoleById, { loading, error }] = useCustomMutation(
    UPDATE_USER_ROLE_BY_ID,
    {
      refetchQueries: [
        { query: GET_ROLES_OF_ORGANIZATION },
        {
          query: GET_ALL_ORGANIZATIONS, variables: {
            page: page,
            perPage: perPage,
            isTrash: false,
            search: searchTerm?.trim(),
          }
        }
      ],
    }
  )


  var { loading, error, data: users, execute } = useCustomQuery(GET_ALL_ORGANIZATIONS, {
    variables: {
      page: page,
      perPage: perPage,
      isTrash: false,
      search: searchTerm?.trim(),
    },
  })

  useEffect(() => {
    if (users) {
      setUserData(users?.Organizations?.organizations || [])
      setTotalCount(users?.Organizations?.totalCount)
    }
  }, [users])

  if (error) {
    console.log(error)
  }



  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Domain",
        accessor: "domain",
      },
      {
        Header: "Description",
        accessor: "description",
      },

      {
        Header: "Action",
        accessor: "_id",
        Cell: ({ value }) => {
          return (
            <div className="d-flex gap-3">
              <Link className="text-info" to={"/organizations/view/" + value}>
                <i className="mdi mdi-eye font-size-18"></i>
              </Link>
              <Link className="text-success" to={"/organizations/edit/" + value}>
                <i className="mdi mdi-pencil font-size-18"></i>
              </Link>
              <Link
                className="text-danger"
                to="#"
                onClick={() => deleteUser(value)}>
                <i className="mdi mdi-delete font-size-18"></i>
              </Link>
            </div>
          )
        },
      },
    ],
    [userData, orgRoles]
  )

  document.title = "Users"

  return (
    <div className="page-content">
      {loading && <PuffLoading />}
      <div className="container-fluid">
        <Breadcrumbs title="Tables" breadcrumbItem="Organizations" />


        <div className={totalCount <= perPage ? "mt-5" : "mt-5"}>
          <Row>
            <Col md={4} className="d-flex align-items-center">
              <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
                <div className="position-relative">
                  <label htmlFor="search-bar-0" className="search-label">
                    <span id="search-bar-0-label" className="sr-only">
                      Search this table
                    </span>
                    <input
                      onChange={e => {
                        setSearchTerm(e.target.value)
                      }}
                      id="search-bar-0"
                      type="text"
                      className="form-control"
                      placeholder={`Search here`}
                      value={searchTerm}
                    />
                  </label>
                  <i className="bx bx-search-alt search-icon"></i>
                </div>
              </div>
            </Col>
            <Col md={8}>
              <Pagination
                totalRows={totalCount}
                pageChangeHandler={setPage}
                rowsPerPage={perPage}
                setRowsPerPage={setPerPage}
                currentPage={page}
              />
            </Col>
          </Row>


          <div className="position-relative">
            {searchLoading && <OvalLoading />}
            <PaginatedTable
              columns={columns}
              data={userData || []}
              isLoading={searchLoading}
            />
          </div>
          <Pagination
            totalRows={totalCount}
            pageChangeHandler={setPage}
            rowsPerPage={perPage}
            setRowsPerPage={setPerPage}
            currentPage={page}
          />

        </div>

      </div>
    </div>
  )
}
Organizations.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default Organizations
