import React, { useEffect, useState } from "react"

import { Alert, Col, Container, Row } from "reactstrap"
import AuthFooter from "./AuthFooter"
import { Link, useNavigate, useParams } from "react-router-dom"
import useCustomMutation from "../../graphql/hooks/useCustomMutation"
import { VERIFY_ACCOUNT_MUTATION } from "../../graphql/mutations/user"
import { toast } from "react-toastify"
import PuffLoading from "components/Loading/PuffLoading"

const VerifyAccount = () => {
    const params = useParams();
    const [token, setToken] = useState(params?.token)
    const navigate = useNavigate()
    const [isTokenValid, setIsTokenValid] = useState(false);
    const [VerifyAccount, { loading, error }] = useCustomMutation(VERIFY_ACCOUNT_MUTATION)

    useEffect(() => {
        if (!token) {
            navigate("/login")
        } else {
            const input = { token }
            VerifyAccount("VerifyAccount", { input })
                .then(response => {
                    if (response) {
                        setIsTokenValid(true)
                        setToken(null)
                        localStorage.removeItem("verifyEmail")
                        navigate("/login")
                    }
                })
                .catch(err => {
                    navigate("/login")
                    console.error(err)
                })
        }
    }, [])
    document.title = "Verify Account";
    return (
        <React.Fragment>
            {loading && <PuffLoading />}
            {isTokenValid && <div>
                <Container fluid className="p-0 login-container-center">
                    <Row className="g-0 login-container">
                     
                        <Col >
                            <div className="auth-full-page-content p-md-5 p-4">
                                <div className="w-100">
                                    <div className="d-flex flex-column h-100">
                                        <div className="my-auto text-center">
                                            <div className="p-2 mt-4">
                                                {isTokenValid && <Alert color="success mt-3" role="alert">
                                                    Your Account Is Verified Successfully!
                                                </Alert>
                                                }
                                            </div>
                                            <div className="mt-1">
                                                <Link
                                                    to="/login"
                                                    className="btn btn-success w-md"
                                                >
                                                    Login
                                                </Link>
                                            </div>
                                        </div>

                                        <div className="mt-4 mt-md-5 text-center">
                                            <AuthFooter />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            }
        </React.Fragment>
    )
}

export default VerifyAccount;
