import { gql } from '@apollo/client';

const GET_ALL_DELIVERY_PARTNERS = gql`
  query DeliveryPartners {
    DeliveryPartners {
      _id
      name
      duration
    }
  }
`

export { GET_ALL_DELIVERY_PARTNERS }
