import { gql } from '@apollo/client';

const CREATE_LABELS = gql`
  mutation Mutation($input: CreateLabelsInput!) {
    CreateLabels(input: $input)
  }
`

export {
  CREATE_LABELS
}
