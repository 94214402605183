import { gql } from '@apollo/client';

const GET_ALL_PRODUCTS = gql`
query GetAllProducts($page: Int, $perPage: Int, $search: String) {
  GetAllProducts(page: $page, perPage: $perPage, search: $search) {
    products {
      _id
      product_id
      name
      sku
      stock_quantity
      estimate
      slug
      type
      status
      description
      price
      total_sales
      isTimeOverride
      createdAt
      updatedAt
      wp_createdAt
      wp_updatedAt
    }
    totalCount
  }
}
`
const GET_PRODUCT_BY_ID = gql`
query GetProductById($getProductById: ID!) {
  GetProductById(id: $getProductById) {
    product_id
    _id
    name
    slug
    type
    status
    sku
    stock_quantity
    description
    estimate
    price
    total_sales
    createdAt
    updatedAt
    wp_createdAt
    wp_updatedAt
  }
}
`
const GET_SUPPLIER_NEW_PRODUCTS = gql`
query GetSupplierNewProducts($supplier: String, $page: Int, $perPage: Int) {
  GetSupplierNewProducts(supplier: $supplier, page: $page, perPage: $perPage) {
    products {
      _id
      title
      sku
      price
      stock_status
      estimate
      isProductApproved
    }
    totalCount
  }
}
`

const GET_SUPPLIER_NEW_PRICES = gql`
query GetSupplierNewPrices($supplier: String, $page: Int, $perPage: Int) {
  GetSupplierNewPrices(supplier: $supplier, page: $page, perPage: $perPage) {
    products {
      _id
      name
      sku
      price
      newPrice
      status
      isPriceApproved
    }
    totalCount
  }
}
`

export {
  GET_ALL_PRODUCTS,
  GET_PRODUCT_BY_ID,
  GET_SUPPLIER_NEW_PRODUCTS,
  GET_SUPPLIER_NEW_PRICES
}
