import React, { } from "react"
import { Navigate } from "react-router-dom"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import Login from "pages/Authentication/Login"
import Register from "pages/Authentication/Register"
import RecoverPassword from "pages/Authentication/RecoverPassword"
import VerifyOtp from "pages/Authentication/VerifyOtp"
import ChangePassword from "pages/Authentication/ChangePassword"
import CreateUser from "pages/User/CreateUser"
import ViewUser from "pages/User/ViewUser"
import User from "pages/User"
import Role from "pages/Role"
import CreateRole from "pages/Role/CreateRole"
import EmailVerification from "pages/Authentication/EmailVerification"
import VerifyAccount from "pages/Authentication/VerifyAccount"
import { LinkedInCallback } from "react-linkedin-login-oauth2"
// import EditCustomer from "pages/Customer/EditCustomer"
import UserProfile from "pages/Authentication/user-profile"
import DomainSetting from "pages/Authentication/DomainSetting"
import SubDomainSetting from "pages/Authentication/SubDomainSetting"
import AllUsers from "pages/AllUsers"
import EditUser from "pages/AllUsers/EditUser"
import Organizations from "pages/Organizations"
import EditOrganization from "pages/Organizations/EditOrganization"
import StockManagement from "pages/StockManagement"
import EditProduct from "pages/StockManagement/EditProduct"
import OrderProcessing from "pages/OrderProcessing"
import ProductInformation from "pages/ProductInformation"
import { LINKS } from "utils/links"
import NewProducts from "pages/NewProducts"
import NewPrices from "pages/NewPrices"
import LabelManagement from "pages/LabelManagement"
import Orders from "pages/OrderProcessing/listing"

const user = JSON.parse(localStorage.getItem("authUser"))
let superAdmin;
user?.roleType?.map((role) => {
  if (role.name == "Super Admin") {
    superAdmin = true;
  } else {
    superAdmin = false;
  }
})


const authProtectedRoutes = [
  // Dashboard
  // { path: LINKS.dashboard, component: <Dashboard /> },

  // Stock Management
  { path: LINKS.stock_management, component: <StockManagement /> },
  { path: `${LINKS.stock_management}/edit/:id`, component: <EditProduct /> },

  // Order Processing
  { path: LINKS.orders, component: <Orders /> },
  { path: `${LINKS.orders}/:id`, component: <OrderProcessing /> },

  // Client Communication
  { path: LINKS.product_information, component: <ProductInformation /> },
  { path: LINKS.new_product, component: <NewProducts /> },
  { path: LINKS.product_prices, component: <NewPrices /> },

  // Label Management
  { path: LINKS.label_management, component: <LabelManagement /> },


  // Roles
  { path: "/roles", component: <Role /> },
  {
    path: "/roles/create",
    component: <CreateRole title="Create Role" isEdit={false} />,
  },
  {
    path: "/roles/edit/:roleId",
    component: <CreateRole title="Update Role" isEdit={true} />,
  },

  { path: "/user-profile", component: <UserProfile /> },
  { path: "/domainsetting", component: <DomainSetting /> },
  { path: "/subdomainsetting", component: <SubDomainSetting /> },
  { path: "/users", component: <User /> },
  {
    path: "/users/create",
    component: <CreateUser title="Create User" isEdit={false} />,
  },
  {
    path: "/users/view/:userId",
    component: <ViewUser title="Update User" />,
  },

  //admin panel
  { path: "/all-users", component: <AllUsers /> },
  { path: "/all-users/edit/:UserId", component: <EditUser isEdit={true} isView={false} /> },
  { path: "/all-users/view/:UserId", component: <EditUser isEdit={false} isView={true} /> },

  // all organizations

  { path: "/organizations", component: <Organizations /> },
  { path: "/organizations/edit/:orgId", component: <EditOrganization isEdit={true} isView={false} /> },
  { path: "/organizations/view/:orgId", component: <EditOrganization isEdit={false} isView={true} /> },


  {
    path: "/",
    exact: true,
    component: <Navigate to={LINKS.stock_management} />,
  },
  {
    path: "*",
    component: <Navigate to={LINKS.stock_management} />,
  },
]

const publicRoutes = [
  { path: "/auth/linkedin/callback", component: <LinkedInCallback /> },
  { path: "/login", component: <Login /> },
  // { path: "/register", component: <Register /> },
  { path: "/verify", component: <EmailVerification /> },
  { path: "/verify-account/:token", component: <VerifyAccount /> },
  { path: "/recover-password", component: <RecoverPassword /> },
  { path: "/verify-otp", component: <VerifyOtp /> },
  { path: "/change-password", component: <ChangePassword /> },
]

export { authProtectedRoutes, publicRoutes }
