import React, { useState } from "react"
import { Link,  useNavigate } from "react-router-dom"
import {
  Col,
  Container,
  Form,
  Row,
  Input,
  Label,
  FormFeedback,
} from "reactstrap"

import PropTypes from "prop-types";

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"
import { SIGNIN_USER_MUTATION } from "../../graphql/mutations/user"
import useCustomMutation from "../../graphql/hooks/useCustomMutation"
import { toast } from "react-toastify"
import PuffLoading from "components/Loading/PuffLoading"
import AuthFooter from "./AuthFooter"
import { UserAuth } from "context/AuthContext"
import { useDispatch } from "react-redux";
import { loginUser } from "store/auth/login/actions"
import withRouter from "components/Common/withRouter";
import { LINKS } from "utils/links";

const Login = props => {
  const [passwordShow, setPasswordShow] = useState(false)
  var [SignIn, { loading }] = useCustomMutation(SIGNIN_USER_MUTATION)
  const { dispatch } = UserAuth()
  const [showOrganization, setShowOrganization] = useState(false)
  const dispatchRedux = useDispatch();
  const navigate = useNavigate()

  //meta title
  document.title = "Login"

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      password: "",
      domain: process.env.REACT_APP_NAME,
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: values => {
      const input = {
        email: values.email.trim().toLowerCase(),
        password: values.password,
        domain: values.domain.trim().toLowerCase(),
      }

      SignIn("SignIn", { input })
        .then(user => {
          if (user) {
            if (user) {
              toast.success("Signin Successfully !");
              const userData = { ...user.user, authToken: user.token };
              dispatch({ type: "LOGIN_SUCCESS", payload: userData });
              dispatchRedux(loginUser(userData, props?.router?.navigate))

              user?.user?.roleType?.map(role => {
                setTimeout(() => {
                  navigate(LINKS.stock_management)
                }, 1000)
              })


            }
          }
        })
        .catch(err => {
          console.error(err)
        })
    },
  })

  return (
    <React.Fragment>
      {loading && <PuffLoading />}
      <div>
        <Container fluid className="p-0 login-container-center">
          <Row className="login-container g-0">

            <Col >
              <div className="auth-full-page-content p-md-5 p-4">
                <div className="w-100">
                  {!showOrganization && (
                    <>
                      <div className="d-flex flex-column h-100">
                        <div className="my-auto">
                          <div>
                            <h5 className="text-primary">Welcome Back !</h5>
                            <p className="text-muted">
                              Sign in to continue to {process.env.REACT_APP_NAME}.
                            </p>
                          </div>

                          <div className="mt-4">
                            <Form
                              className="form-horizontal"
                              onSubmit={e => {
                                e.preventDefault()
                                validation.handleSubmit()
                                return false
                              }}
                            >
                              <div className="mb-3">
                                <Label className="form-label">Email</Label>
                                <Input
                                  name="email"
                                  className="form-control"
                                  placeholder="Enter email"
                                  type="email"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.email || ""}
                                  invalid={
                                    validation.touched.email &&
                                      validation.errors.email
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.email &&
                                  validation.errors.email ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.email}
                                  </FormFeedback>
                                ) : null}
                              </div>

                              <div className="mb-3">
                                <Label className="form-label">Password</Label>
                                <div className="input-group auth-pass-inputgroup">
                                  <Input
                                    name="password"
                                    value={validation.values.password || ""}
                                    type={passwordShow ? "text" : "password"}
                                    placeholder="Enter Password"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    invalid={
                                      validation.touched.password &&
                                        validation.errors.password
                                        ? true
                                        : false
                                    }
                                  />
                                  <button
                                    onClick={() =>
                                      setPasswordShow(!passwordShow)
                                    }
                                    className="btn btn-light "
                                    type="button"
                                    id="password-addon"
                                  >
                                    {passwordShow ? (
                                      <i className="mdi mdi-eye-outline"></i>
                                    ) : (
                                      <i className="mdi mdi-eye-off-outline"></i>
                                    )}
                                  </button>
                                </div>
                                {validation.touched.password &&
                                  validation.errors.password ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.password}
                                  </FormFeedback>
                                ) : null}
                                <div className="float-end">
                                  <Link
                                    to="/recover-password"
                                    className="text-muted"
                                  >
                                    Forgot password?
                                  </Link>
                                </div>
                              </div>

                              {/* <div className="mb-3">
                                <Label className="form-label">
                                  Subdomain or Organization Name
                                </Label>
                                <Input
                                  name="domain"
                                  type="text"
                                  placeholder="Enter domain"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.domain || ""}
                                  invalid={
                                    validation.touched.domain &&
                                      validation.errors.domain
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.domain &&
                                  validation.errors.domain ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.domain}
                                  </FormFeedback>
                                ) : null}
                              </div> */}

                              <div className="form-check">
                                <Input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="auth-remember-check"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="auth-remember-check"
                                >
                                  Remember me
                                </label>
                              </div>

                              <div className="mt-3 d-grid">
                                <button
                                  className="btn btn-primary btn-block "
                                  type="submit"
                                >
                                  Log In
                                </button>
                              </div>
                            </Form>
                            {/* <Form action="dashboard">
                              <div className="mt-4 text-center">
                                <h5 className="font-size-14 mb-3">
                                  Sign in with
                                </h5>

                                <ul className="list-inline">
                                  <li className="list-inline-item">
                                    <Link
                                      onClick={() => handleSignIn(socialLoginTypes.FACEBOOK)}
                                      className="social-list-item bg-primary text-white border-primary me-1"
                                    >
                                      <i className="fab fa-facebook"></i>
                                    </Link>
                                  </li>

                                  <li className="list-inline-item">
                                    <Link
                                      onClick={() => handleSignIn(socialLoginTypes.LINKEDIN)}
                                      className="social-list-item bg-primary text-white"
                                    >
                                      <i className="fab fa-linkedin"></i>
                                    </Link>
                                  </li>
                                  <li className="list-inline-item">
                                    <Link
                                      onClick={() => handleSignIn(socialLoginTypes.GOOGLE)}
                                      className="social-list-item bg-danger text-white border-danger"
                                    >
                                      <i className="fab fa-google"></i>
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </Form> */}
                            {/* <div className="mt-2 text-center">
                              <p>
                                Don&apos;t have an account? {" "}
                                <Link
                                  to="/register"
                                  className="fw-medium text-primary"
                                >
                                  Signup now
                                </Link>
                              </p>
                            </div> */}
                          </div>
                        </div>

                        <div className="mt-2 text-center">
                          <AuthFooter />
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
};
