import { gql } from '@apollo/client';

const PRINT_LABELS = gql`
  mutation CreateLabel($input: CreateLabelInput!) {
    createLabel(input: $input) {
       stream
    }
  }
`

export {
  PRINT_LABELS
}
