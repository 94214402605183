import { gql } from '@apollo/client';

const UPDATE_ORGANIZATION = gql`
mutation Mutation($updateOrganizationId: ID!, $input: UpdateOrganizationInput!) {
    UpdateOrganization(id: $updateOrganizationId, input: $input) {
      _id
      domain
      description
      name
    }
  }
`

const DELETE_ORGANIZATION = gql`
mutation DeleteOrganization($deleteOrganizationId: ID!) {
  DeleteOrganization(id: $deleteOrganizationId) {
    _id
  }
}
`



export {
  UPDATE_ORGANIZATION,
  DELETE_ORGANIZATION
}
