import React from "react"
import PropTypes from 'prop-types'
import { Link, useLocation, useNavigate } from "react-router-dom"
import { Row, Col, BreadcrumbItem } from "reactstrap"

const Breadcrumb = props => {

  const navigate = useNavigate();
  const location = useLocation()
  return (
    <Row>
      <Col className="col-12">
        <div style={{justifyContent: 'space-between'}} className="page-title-box d-sm-flex align-items-center justify-content-between">
          <div style={{display: 'flex', alignItems: 'center'}}>
            {location.pathname.includes("/users/view/") ? (
              <button
                type="button"
                className="btn btn-sm px-3 font-size-16 header-item"
                data-toggle="collapse"
                onClick={() => {
                  navigate(-1)
                }}
              >
                <i className="fa fa-fw fa-arrow-left" />
              </button>
            ) : null}
              
              <h4 className="mb-sm-0 font-size-18">{props.breadcrumbItem}</h4>
          </div>
        
          <div className="page-title-right">
            <ol className="breadcrumb m-0">
              
              <BreadcrumbItem>
                <Link to="#">{props.title}</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>
                <Link to="#">{props.breadcrumbItem}</Link>
              </BreadcrumbItem>
            </ol>
          </div>
        </div>
      </Col>
    </Row>
  )
}

Breadcrumb.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string
}

export default Breadcrumb
