import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
} from "reactstrap"
import withRouter from "components/Common/withRouter"
import Breadcrumb from "../../components/Common/Breadcrumb"
import avatar from "../../assets/images/users/avatar-1.jpg"

const SubDomainSetting = () => {
  document.title = "Profile | Skote - React Admin & Dashboard Template"
  const [email, setemail] = useState("")
  const [name, setname] = useState("")

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Skote" breadcrumbItem="Profile" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="ms-3">
                      <div className="mb-1">
                        <img
                          src={avatar}
                          alt=""
                          className="avatar-md rounded-circle img-thumbnail"
                        />
                      </div>
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <h5>{name}</h5>
                        <p className="mb-1">{email}</p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Card>
            <CardBody>
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <div className="form-group">
                  <Label className="form-label">Sub-Domain Name</Label>
                  <Input
                    className="form-control"
                    placeholder="Enter Domain Name"
                    type="text"
                    value={""}
                  />
                </div>
                <div className="form-group">
                  <Label className="form-label">Sub-Domain</Label>
                  <Input
                    className="form-control"
                    placeholder="Enter Domain (abc.com) "
                    type="text"
                    value={""}
                  />
                </div>
                <div className="text-center mt-4">
                  <Button type="submit" color="success">
                    Update Domain
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(SubDomainSetting)
