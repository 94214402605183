// src/components/filter.
import React, { useEffect, useMemo, useState } from "react"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import PuffLoading from "components/Loading/PuffLoading"
import { Col, Row } from "reactstrap"
import Pagination from "../../components/pagination"
import { Link } from "react-router-dom"
import { useLazyQuery } from "@apollo/client"
import PaginatedTable from "components/Table/PaginatedTable"
import OvalLoading from "components/Loading/OvalLoading"
import { LINKS } from "utils/links"
import { GET_ALL_ORDERS } from "graphql/queries/orders"
import { ORDER_STATUS } from "utils/constants"
import { toast } from "react-toastify"

function Orders() {
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(25)
  const [totalCount, setTotalCount] = useState(0)
  const [searchTerm, setSearchTerm] = useState("")
  const [ordersData, setOrdersData] = useState([])
  const [filter, setFilter] = useState("all")
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("")

  // Server side searching
  const [search, { loading: searchLoading, __, data, error }] =
    useLazyQuery(GET_ALL_ORDERS, {
      onCompleted: data => {
        const totalCount = data?.GetAllOrders?.totalCount
        if (page > Math.ceil(totalCount / perPage)) {
          setPage(1)
        }
        setOrdersData(data?.GetAllOrders?.orders || [])
        setTotalCount(totalCount)
      },
    })

  // First time load all data with pagination
  useEffect(() => {
    if (debouncedSearchTerm.trim() === "") {
      search({
        variables: {
          page: page,
          perPage: perPage,
          search: searchTerm?.trim(),
          filter: filter !== ORDER_STATUS[0] ? filter : "",
        },
      })
    }
  }, [debouncedSearchTerm, page, perPage])

  const getProductsHandle = (e) => {
    e.preventDefault()
    if(!searchTerm) { return toast.warn("Enter Order ID")}
    search({
      variables: {
        page: page,
        perPage: perPage,
        search: searchTerm?.trim(),
        filter: filter !== ORDER_STATUS[0] ? filter : "",
      },
    })
  }

  if (error) {
    console.log(error)
  }

  const columns = useMemo(
    () => [
      {
        Header: "Order ID",
        accessor: "order_id",
      },
      {
        Header: "Price",
        accessor: "total",
      },
      {
        Header: "Currency",
        accessor: "currency",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Country",
        accessor: "shipping.country",
      },
      {
        Header: "Action",
        accessor: "_id",
        Cell: (value) => {
          const order_id = value?.row?.original?.order_id
          return (
            <div className="d-flex gap-3">
              <Link className="text-info" to={`${LINKS.orders}/${order_id}`}>
                <i className="mdi mdi-eye font-size-18"></i>
              </Link>
            </div>
          )
        },
      },
    ],
    [ordersData]
  )

  document.title = "Orders"

  return (
    <div className="page-content">
      {searchLoading && <PuffLoading />}
      <div className="container-fluid">
        <Breadcrumbs title="Orders" breadcrumbItem="" />

        <div className={"mt-3"}>
          <div className="d-flex justify-content-end align-items-center mb-2 gap-2">
            <h5 className="mb-0">Filter: </h5>
            <div className="branch_status" style={{ maxWidth: 300 }}>
              <select
                className="form-select"
                style={{ minWidth: 140, textTransform: "uppercase" }}
                value={filter}
                onChange={e => {
                  if (!e.target.value) return
                  setFilter(e.target.value)
                  search({
                    variables: {
                      page: page,
                      perPage: perPage,
                      search: searchTerm?.trim(),
                      filter: e.target.value !== ORDER_STATUS[0] ? e.target.value : "",
                    },
                  })
                }
                }
              >
                {ORDER_STATUS &&
                  ORDER_STATUS
                    ?.map((status) => (
                      <option
                        key={status}
                        value={status}
                        selected={status.toLowerCase() === filter?.toLowerCase()}
                      >
                        {status}
                      </option>
                    ))}
              </select>
            </div >
          </div>
          <Row>
            <Col md={4} className="d-flex align-items-center">
              <form
                onSubmit={getProductsHandle}
                className="search-box d-flex align-items-center gap-2 mb-2"
              >
                <div className="position-relative">
                  <label htmlFor="search-bar-0" className="search-label mb-0">
                    <span id="search-bar-0-label" className="sr-only">
                      Enter Order ID
                    </span>
                    <input
                      onChange={e => {
                        setSearchTerm(e.target.value)
                      }}
                      id="search-bar-0"
                      type="number"
                      className="form-control"
                      placeholder={`Enter Order ID`}
                      value={searchTerm}
                    />
                  </label>
                  <i className="bx bx-search-alt search-icon"></i>
                </div>
                <button type="submit" className="btn btn-primary waves-effect waves-light btn btn-primary">Search</button>
              </form>
            </Col>
            <Col md={8}>
              <Pagination
                totalRows={totalCount}
                pageChangeHandler={setPage}
                rowsPerPage={perPage}
                setRowsPerPage={setPerPage}
                currentPage={page}
              />
            </Col>
          </Row>


          <div className="position-relative">
            {searchLoading && <OvalLoading />}
            <PaginatedTable
              columns={columns}
              data={ordersData || []}
              isLoading={searchLoading}
            />
          </div>
          <Pagination
            totalRows={totalCount}
            pageChangeHandler={setPage}
            rowsPerPage={perPage}
            setRowsPerPage={setPerPage}
            currentPage={page}
          />
        </div>
      </div>
    </div>
  )
}

export default Orders
