import React from "react"

function AuthFooter() {
  return (
    <p className="mb-0">
      © {new Date().getFullYear()} {process.env.REACT_APP_NAME}
    </p>
  )
}

export default AuthFooter
