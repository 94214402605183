import { AuthContext } from "context/AuthContext"
import React, { useContext } from "react"
import { Navigate, useLocation, useNavigate } from "react-router-dom"
const Authmiddleware = props => {
  const navigate = useNavigate()
  const location = useLocation()
  const { user, dispatch } = useContext(AuthContext)
  if (!localStorage.getItem("authUser") || !user || user === undefined) {
    if (location.pathname === "/" && location.search) {
      const search = new URLSearchParams(location.search)
      // dispatch({ type: "LOGOUT" })
      // navigate("/verify-account/" + search.get("token"))
      return (
        <Navigate
          to={{
            pathname: "/verify-account/" + search.get("token"),
            state: { from: props.location },
          }}
        />
      )
    } else {
      return (
        <Navigate
          to={{ pathname: "/login", state: { from: props.location } }}
        />
      )
      // navigate("/login")
    }
  } else {
    return <React.Fragment>{props.children}</React.Fragment>
  }
}

export default Authmiddleware
