import React, { createContext, useContext, useEffect, useReducer } from "react"
import { GoogleAuthProvider, signInWithPopup, FacebookAuthProvider, } from "firebase/auth"
import { auth } from "pages/firebase"

const INITIAL_STATE = {
  user: JSON.parse(localStorage.getItem("authUser")) || null,
  loading: false
}

export const AuthContext = createContext(INITIAL_STATE)

const AuthReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN_START":
      return {
        user: null,
      }
    case "LOGIN_SUCCESS":
      return {
        user: action.payload,
      }
    case "LOGIN_FAILURE":
      return {
        user: null,
      }
    case "LOGOUT":
      return {
        user: null,
      }
    default:
      return state
  }
}

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AuthReducer, INITIAL_STATE)

  useEffect(() => {
    localStorage.setItem("authUser", JSON.stringify(state.user))
  }, [state.user])

  const googleSignIn = () => {
    const provider = new GoogleAuthProvider();
  
    return new Promise((resolve, reject) => {
      const popupWindow = signInWithPopup(auth, provider);
  
      // Check if the popup is closed or not when the page is unloaded
      window.onbeforeunload = () => {
        if (popupWindow && !popupWindow.closed) {
          reject(new Error("Google Sign-In popup closed"));
        }
      };
  
      popupWindow
        .then((result) => {
          // This gives you a Google Access Token. You can use it to access the Google API.
          const credential = GoogleAuthProvider.credentialFromResult(result);
          const token = credential.accessToken;
          // The signed-in user info.
          // console.log(result)
          const user = JSON.stringify(result.user);
          sessionStorage.setItem("OAuthUser", JSON.stringify(result.user));
          // IdP data available using getAdditionalUserInfo(result)
          // ...
          resolve(result);
        })
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          // The email of the user's account used.
          const email = error.customData.email;
          // The AuthCredential type that was used.
          const credential = GoogleAuthProvider.credentialFromError(error);
          // ...
          reject(error);
        });
    });
  };

  const facebookSignIn = () => {
    const provider = new FacebookAuthProvider();
  
    return new Promise((resolve, reject) => {
      const popupWindow = signInWithPopup(auth, provider);
  
      // Check if the popup is closed or not when the page is unloaded
      window.onbeforeunload = () => {
        if (popupWindow && !popupWindow.closed) {
          reject(new Error("Facebook Sign-In popup closed"));
        }
      };
  
      // Attach event listeners to handle sign-in result
      popupWindow.then((result) => {
        // This gives you a Facebook Access Token. You can use it to access the Facebook API.
        const credential = FacebookAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
  
        // The signed-in user info.
        sessionStorage.setItem("OAuthUser", JSON.stringify(result.user.providerData[0]));
  
        // IdP data available using getAdditionalUserInfo(result)
        // ...
  
        resolve(result);
      }).catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;

        if(errorCode != 'auth/account-exists-with-different-credential')
          reject(error);  

        const errorMessage = error.message;
  
        // The email of the user's account used.
        const email = error.customData?.email;
  
        // The AuthCredential type that was used.
        const credential = FacebookAuthProvider.credentialFromError(error);
        const user = {
          email: email,
          credential: credential
        }
        sessionStorage.setItem("OAuthUser", JSON.stringify(user));
        resolve(error);
        // ...

      });
    });
  };
  
  return (
    <AuthContext.Provider
      value={{
        user: state.user,
        loading: state.loading,
        errors: state.errors,
        dispatch,
        googleSignIn,
        facebookSignIn
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const UserAuth = () => {
  return useContext(AuthContext)
}
