import { gql } from '@apollo/client';

const UPDATE_ORDER_PRODUCT_STATUS = gql`
  mutation Mutation($productId: ID!, $orderId: ID!, $input: UpdateProductStatusInput!) {
    UpdateProductStatus(productId: $productId, orderId: $orderId, input: $input)
  }
`

const UPDATE_ORDER_PRODUCT_PARTNER = gql`
  mutation Mutation($productId: ID!, $orderId: ID!, $input: UpdateProductPartnerInput!) {
    UpdateProductPartner(productId: $productId, orderId: $orderId, input: $input)
  }
`

const SEND_ORDER_EMAIL = gql`
  mutation Mutation($orderId: ID!, $input: SendOrderEmailInput!) {
    SendOrderEmail(id: $orderId, input: $input)
  }
`

const ADD_ORDER_NOTES = gql`
  mutation AddOrderNotes($id: ID!, $input: OrderNotesInput) {
    AddOrderNotes(id: $id, input: $input)
  }
`
export {
  UPDATE_ORDER_PRODUCT_STATUS,
  UPDATE_ORDER_PRODUCT_PARTNER,
  SEND_ORDER_EMAIL,
  ADD_ORDER_NOTES
}
