import React, { useState, useEffect } from "react"
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
} from "reactstrap"
import { SHIPMENT_SUPPLIER } from "utils/constants"
import "./ShipmentConfirmation.scss"
import dpdImage from "../../../assets/images/deliveryPartner/dpd-logo-3.png"
const keysToIncludeInAddress = [
  "Name",
  "Street",
  "PostalCode",
  "Place",
  "State",
  "Country",
]

const ShipmentConfirmationForDPD = (props, args) => {
  const {
    show,
    tittle,
    deliveryPartner,
    activeShipmentDetail = [],
    customerDetail = null,
    defaultValues = null,
    onConfirmClick,
    onCloseClick,
  } = props
 
  const [deliveryInfo, setDeliveryInformation] = useState({
    delivery_instruction: "",
  })

  const [packageInfo, setPackageInfo] = useState([])


  useEffect(() => {
    if (activeShipmentDetail?.length > 0) {
      const data = activeShipmentDetail?.map((product, i) => ({
        productName: product?.item?.name, //  new added
        product_id: product?.product_id,
        supplier: null,
        pickup_instruction: null,
        quantity: product?.quantity,
        packages: Array.from(
          { length: product?.item?.packages ? product?.item?.packages : 1 },
          (v, i) => i + 1
        ).map((item, i) => ({
          Depth:
            product?.item?.packages_dimensions[i]?.length?.toString() || "",
          Height:
            product?.item?.packages_dimensions[i]?.height?.toString() || "",
          Width: product?.item?.packages_dimensions[i]?.width?.toString() || "",
          Weight:
            product?.item?.packages_dimensions[i]?.weight?.toString() || "",
          ArticleWeight:
            product?.item?.packages_dimensions[i]?.weight?.toString() || "",
          Price: product?.price || 0,
        })),
      }))
      setPackageInfo(data)
    }
  }, [activeShipmentDetail, show])

  const handleDeliveryInfo = e => {
    const { value } = e.target
    setDeliveryInformation({
      delivery_instruction: value,
    })
  }

  const handlePackageInfo = (productId, packageIndex, newPackageData) => {
    setPackageInfo(prevProducts =>
      prevProducts.map(product =>
        product.product_id === productId
          ? {
              ...product,
              packages: product.packages.map((pkg, index) =>
                index === packageIndex ? { ...pkg, ...newPackageData } : pkg
              ),
            }
          : product
      )
    )
  }

  const updateSupplier = (productId, newSupplier) => {
    setPackageInfo(prevProducts =>
      prevProducts.map(product =>
        product.product_id === productId
          ? { ...product, supplier: newSupplier }
          : product
      )
    )
  }

  const updatePickUpInfo = (productId, info) => {
    setPackageInfo(prevProducts =>
      prevProducts.map(product =>
        product.product_id === productId
          ? { ...product, pickup_instruction: info }
          : product
      )
    )
  }

  const saveData = () => {
    const payload = {
      deliveryInfo: deliveryInfo,
      package_info: packageInfo,
      deliveryPartner: deliveryPartner,
    }
    onConfirmClick(payload)
  }

  const closeModel = () => {
    setDeliveryInformation({
      delivery_instruction: "",
    })

    setPackageInfo([])
    onCloseClick()
  }

  const handleSubmit = e => {
    e.preventDefault()
    saveData()
    closeModel()
  }

  const createAddressString = (index, keys) => {
    const supplierAddress = SHIPMENT_SUPPLIER.find(
      o => o.value === packageInfo[index]?.supplier
    )?.address
    return keys
      .map(key => supplierAddress?.Address[key])
      .filter(value => value) // Filter out null or undefined values
      .join(", ")
  }

  return (
    <div>
      <Modal
        className="shipment-confirmation"
        isOpen={show}
        toggle={() => closeModel()}
        centered={true}
        backdrop={true}
        size="xl"
        {...args}
      >
        <ModalHeader toggle={() => closeModel()} className="header sticky-top">
          <p className="delivery-partner mb-0">{tittle}</p>
        </ModalHeader>
        <ModalBody>
          <form onSubmit={e => handleSubmit(e)}>
            <div className="d-flex align-items-start">
              <div className="div-details">
                <p className="delivery-partner">
                  Delivery Partner : {deliveryPartner}
                </p>
                <img
                  src={dpdImage}
                  alt="logique"
                  className="dpd-delivery-partner-image"
                />
              </div>
              <div className="customer-detail mt-2">
                <p className="fs-5 fw-bold">Customer Information</p>
                <p className="custom-p-spacing">{customerDetail?.name}</p>
                <p className="custom-p-spacing">{customerDetail?.address}</p>
                <p className="custom-p-spacing">{`${customerDetail?.country}, ${customerDetail?.city}`}</p>
                <p className="custom-p-spacing">{customerDetail?.telephone}</p>
                <p className="custom-p-spacing">
                  {customerDetail?.email?.split(":")[1]}
                </p>
              </div>
            </div>

            <div className="scroll-details">
              <Card className="main-card">
                {activeShipmentDetail?.map((product, i) => {
                  return (
                    <div className="product-section mt-3" key={i}>
                      <div className="product-info d-flex align-items-center justify-content-between">
                        <div>
                          <p className="title text-uppercase">Name</p>
                          <p className="fs-6 fw-normal">
                            {product?.item?.name}
                          </p>
                        </div>
                        <div>
                          <p className="title text-uppercase">Quantity</p>
                          <p className="text-center">{product?.quantity}</p>
                        </div>
                        <div>
                          <p className="title text-uppercase">Packages</p>
                          <p className="text-center">
                            {product?.item?.packages
                              ? product?.item?.packages
                              : 1}
                          </p>
                        </div>
                      </div>
                      <div className="package-info mt-3">
                        <div className="package-header">
                          <div className="row">
                            <div className="col-md-2"></div>
                            <div className="col-md-10 d-flex align-items-center">
                              <span className="title title-width">
                                DEPTH<i className="error">*</i>
                              </span>
                              <span className="title ms-3 title-width">
                                HEIGHT<i className="error">*</i>
                              </span>
                              <span className="title ms-3 title-width">
                                WIDTH<i className="error">*</i>
                              </span>
                              <span className="title ms-3 title-width">
                                WEIGHT<i className="error">*</i>
                              </span>
                            </div>
                          </div>
                        </div>
                        {packageInfo?.length > 0 &&
                          packageInfo[i]?.packages?.map(
                            (pkg, package_index) => {
                              return (
                                <div key={package_index} className="row mt-2">
                                  <div className="col-md-2">
                                    <p>Package {package_index + 1}</p>
                                  </div>
                                  <div className="col-md-10 d-flex align-items-center">
                                    <input
                                      type="number"
                                      value={pkg.Depth}
                                      className="form-control element-width"
                                      onChange={e =>
                                        handlePackageInfo(
                                          product.product_id,
                                          package_index,
                                          { Depth: e.target.value }
                                        )
                                      }
                                      required
                                      min="1"
                                      step="any"
                                    />
                                    <input
                                      type="number"
                                      value={pkg.Height}
                                      className="form-control ms-3 element-width"
                                      onChange={e =>
                                        handlePackageInfo(
                                          product.product_id,
                                          package_index,
                                          { Height: e.target.value }
                                        )
                                      }
                                      required
                                      min="1"
                                      step="any"
                                    />
                                    <input
                                      type="number"
                                      value={pkg.Width}
                                      className="form-control ms-3 element-width"
                                      onChange={e =>
                                        handlePackageInfo(
                                          product.product_id,
                                          package_index,
                                          { Width: e.target.value }
                                        )
                                      }
                                      required
                                      min="1"
                                      step="any"
                                    />
                                    <input
                                      type="number"
                                      value={pkg.Weight}
                                      className="form-control ms-3 element-width"
                                      onChange={e =>
                                        handlePackageInfo(
                                          product.product_id,
                                          package_index,
                                          { Weight: e.target.value }
                                        )
                                      }
                                      required
                                      min="1"
                                      step="any"
                                    />
                                    {/* <input type="number" value={pkg.ArticleWeight} className='form-control ms-3 element-width' onChange={(e) => handlePackageInfo(product.product_id, package_index, { "ArticleWeight": e.target.value })} required min="1" step="any"/> */}
                                  </div>
                                </div>
                              )
                            }
                          )}
                      </div>
                    </div>
                  )
                })}
                <div className="row supplier-info mt-3">
                  <div className="col-md-4">
                    <p className="title mb-1">Supplier</p>
                    <div className="d-flex">

                   
                    <select
                      required
                      className="form-select mb-2"
                      onChange={e =>
                        updateSupplier(
                          activeShipmentDetail[0].item.product_id,
                          e.target.value
                        )
                      }
                    >
                      {SHIPMENT_SUPPLIER.map((item, i) => (
                        <option key={i} value={item.value}>
                          {item.label}
                        </option>
                      ))}
                    </select>
                      </div>
                  </div>
                  
                  <p className="title col-md-8 mt-4 mb-3 d-flex address">
                        {createAddressString(0, keysToIncludeInAddress)}
                      </p>
                  
                </div>
              </Card>
            </div>

            <ModalFooter>
              <Button color="secondary" onClick={() => closeModel()}>
                Cancel
              </Button>{" "}
              <Button type="submit" color="primary">
                Confim
              </Button>
            </ModalFooter>
          </form>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default ShipmentConfirmationForDPD
