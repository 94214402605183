import PropTypes from "prop-types"
import React, { useContext, useEffect, useState } from "react"
import { connect } from "react-redux"
import withRouter from "components/Common/withRouter"

//i18n
import { withTranslation } from "react-i18next"
import SidebarContent from "./SidebarContent"

import { Link } from "react-router-dom"


import { AuthContext } from "context/AuthContext"
import SidebarContentSuperAdmin from "./SidebarContentSuperAdmin"

const Sidebar = props => {
  const { user } = useContext(AuthContext)
  const [superAdmin, setSuperAdmin] = useState(false)
  useEffect(() => {
    user?.roleType?.map(role => {
      if (role?.name == "Super Admin") {
        setSuperAdmin(true)
      }
    })
  }, [])
  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="navbar-brand-box">
          
          <Link
            to="/"
            className="logo logo-light d-flex align-items-center justify-content-center g-2"
          >
            
            <span
              className="d-none d-md-inline"
              style={{
                fontSize: 22,
                textTransform: "capitalize",
                letterSpacing: 1.4,
              }}
            >
              {process.env.REACT_APP_NAME}
            </span>
            
          </Link>
        </div>
        {superAdmin == true ? (
          <div data-simplebar className="h-100">
            {props.type !== "condensed" ? <SidebarContentSuperAdmin /> : <SidebarContentSuperAdmin />}
          </div>
        ): (
          <div data-simplebar className="h-100">
            {props.type !== "condensed" ? <SidebarContent /> : <SidebarContent />}
          </div>
        )}
        
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  )
}

Sidebar.propTypes = {
  type: PropTypes.string,
}

const mapStatetoProps = state => {
  return {
    layout: state.Layout,
  }
}
export default connect(
  mapStatetoProps,
  {}
)(withRouter(withTranslation()(Sidebar)))
