import React, { useEffect, useMemo, useState } from "react"
import { useSortBy, useTable } from "react-table"
import styles from "./styles.module.css"
import PuffLoading from "components/Loading/PuffLoading"
import { Table } from "reactstrap"
import { Checkbox, FormControlLabel } from "@mui/material"

const PaginatedTable = ({
  columns,
  data,
  isLoading,
  manualPagination = false,
  showCheckbox = false,
  selectedProducts,
  selectedIds
}) => {
  const columnData = useMemo(() => columns, [columns])
  const rowData = useMemo(() => data, [data])

  const selectedIdsData = useMemo(() => selectedIds, [selectedIds])
  const [selectedIdsCopy, setSelectedIdsCopy] = useState([])

  useEffect(() => {
    setSelectedIdsCopy(selectedIdsData)
  }, [selectedIdsData])


  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns: columnData,
        data: rowData,
        manualPagination,
      },
      useSortBy
    )

  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""
  }

  return (
    <>
      {isLoading ? (
        <PuffLoading />
      ) : (
        <>
          <div
            className="table-responsive react-table mt-1"
            style={{ maxHeight: "650px" }}
          >
            <Table {...getTableProps()} className="custom-header-css">
              <thead
                className="table-light table-head"
              >
                {headerGroups.map((headerGroup, i) => (
                  <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                    {showCheckbox && <th></th>}
                    {headerGroup.headers.map((column, i) => {
                      return (
                        <th key={i} {...column.getHeaderProps()}>
                          <div
                            className="mb-2"
                            {...column.Header == "Code" || column.Header == "Address" || column.Header == "Action" ? null : column.getSortByToggleProps()}
                          >
                            {column.render("Header")?.toUpperCase()}
                            {generateSortingIndicator(column)}
                          </div>
                        </th>
                      )
                    })}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows && rows?.length > 0 ?
                  rows.map((row, i) => {
                    prepareRow(row)
                    return (
                      <tr key={`${i}_'dm'`} {...row.getRowProps()}>
                        {showCheckbox &&
                          <td>
                            <Checkbox onClick={(e) => {
                              selectedProducts(row.cells[0].value, e.target.checked)
                            }}
                              checked={selectedIds.includes(row.cells[0].value) ? true : false}
                            />
                          </td>}

                        {row.cells.map((cell, i) => {
                          return (
                            <td
                              style={{ fontSize: 12 }}
                              key={i}
                              {...cell.getCellProps()}
                            >
                              {cell.render("Cell")}
                            </td>
                          )
                        })}
                      </tr>
                    )
                  })
                  : <p><strong>No Data Found</strong></p>
                }
              </tbody>
            </Table>
          </div>
          {manualPagination && (
            <div>
              {/* YOUR CLIENT SIDE PAGINATION COMPONENT BUILT USING REACT-TABLE UTILITY FUNCTIONS */}
            </div>
          )}
        </>
      )}
    </>
  )
}

export default PaginatedTable
