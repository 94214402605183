import { useMutation } from "@apollo/client"

function useCustomMutation(mutationQuery, options={}) {
  const [mutationFunction, { loading, error }] = useMutation(mutationQuery, options)

  const performMutation = async (operationName, variables) => {
    try {
      const { data } = await mutationFunction({
        variables,
        context: { operationName },
      })
      return data[operationName]
    } catch (e) {
      console.error(`Error performing mutation ${operationName}:`, e)
      throw e
    }
  }

  return [performMutation, { loading, error }]
}

export default useCustomMutation