import React, { useState, useEffect, useContext } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
} from "reactstrap"
import withRouter from "components/Common/withRouter"
import Breadcrumb from "../../components/Common/Breadcrumb"
import avatar from "../../assets/images/users/avatar-1.jpg"
import { AuthContext } from "context/AuthContext"
import { useFormik } from "formik"
import { UPDATE_USER_PROFILE } from "../../graphql/mutations/user"
import useCustomMutation from "../../graphql/hooks/useCustomMutation"

const DomainSetting = () => {
  const { user, dispatch } = useContext(AuthContext)
  document.title = "Profile | Skote - React Admin & Dashboard Template"
  const [email, setemail] = useState(user.email)
  const [organizationName, setOrganizationName] = useState(
    user.organization.name
  )
  const [UpdateUser, { loading: UpdateUserInput }] =
    useCustomMutation(UPDATE_USER_PROFILE)

  const validation = useFormik({
    enableReinitialize: true,
    onSubmit: values => {
      dispatch(editProfile(values))
    },
  })
  const UpdateOrganization = () => {
    const organization = user.organization.name
    const input = {
      organization: organizationName,
    }
    const id = JSON.parse(localStorage.getItem("authUser"))._id
    UpdateUser("UpdateUser", { updateUserId: id, input: input })
      .then(user => {
        toast.success("Organization Name Updated!")
        dispatch({
          type: "LOGIN_SUCCESS",
          payload: { ...user, organization: organizationName },
        })
        setTimeout(() => { }, 1000)
      })
      .catch(err => {
        console.error(err)
      })
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Skote" breadcrumbItem="Profile" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="ms-3">
                      <div className="mb-1">
                        <img
                          src={avatar}
                          alt=""
                          className="avatar-md rounded-circle img-thumbnail"
                        />
                      </div>
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <p className="mb-1">{email}</p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Card>
            <CardBody>
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <div className="form-group">
                  <Label className="form-label">Organization Name</Label>
                  <Input
                    className="form-control"
                    placeholder="Enter Organization Name"
                    type="text"
                    onChange={e => setOrganizationName(e.target.value)}
                    value={organizationName}
                  />
                </div>
                <div className="form-group">
                  <Label className="form-label">Domain</Label>
                  <Input
                    className="form-control"
                    placeholder="Enter Domain (abc.com) "
                    type="text"
                    value={""}
                  />
                </div>
                <div className="text-center mt-4">
                  <Button
                    type="submit"
                    color="success"
                    onClick={UpdateOrganization}
                  >
                    Update Domain
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(DomainSetting)
