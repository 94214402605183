import React, { useEffect, useMemo, useState } from 'react';
import { useSortBy, useTable } from "react-table"
import CancelIcon from '@mui/icons-material/Cancel';
import { Spinner, Table } from "reactstrap"
import { Stack } from '@mui/system';
import ShipmentLoader from 'components/Common/ShipmentLoader';


const ExistingShipmentsTable = ({ shipmentIndex, shipmentValue, columns, isAllowHideSection, onPrintLabelClick, isPrintLabelLoading, hasLiveShipmentId, existingShipmentData, shipmentDeliveryPartner }) => {
  const [shipmentData, setShipmentData] = useState([]);

  const columnData = useMemo(() => columns, [columns])
  const rowData = useMemo(() => shipmentData, [shipmentData])

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns: columnData,
        data: rowData,
        // manualPagination,
      },
      useSortBy
    )
  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""
  }

  useEffect(() => {
    if (existingShipmentData.length) {
      setShipmentData(existingShipmentData)
    }
  }, [existingShipmentData])

  return (
    <>
      {isPrintLabelLoading && <ShipmentLoader messageText="Fetching Print Lables..." />}
      <div
        className="table-responsive react-table mt-1"
        style={{ maxHeight: "650px" }}
      >
        <Table {...getTableProps()} className="custom-header-css">
          <thead className="table-light table-head">
            {headerGroups.map((headerGroup, i) => (
              <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                <Stack display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                  <h5 style={{ fontSize: "14px", margin: 0 }}>
                    {/* {`Shipment #${shipmentValue}`} */}
                    {hasLiveShipmentId ? `#${shipmentIndex + 1} Shipment Id : ${shipmentValue}` : `#${shipmentValue}`}
                  </h5>

                  <div className='d-flex justify-content-between align-items-center'>
                    <h5 style={{ fontSize: "14px", margin: 0 }} className="me-3">Delivery Partner : {shipmentDeliveryPartner}</h5>
                    <button
                      type="submit"
                      className="btn btn-primary waves-effect waves-light"
                      onClick={() => onPrintLabelClick(shipmentValue,shipmentDeliveryPartner)}
                      disabled={isPrintLabelLoading}
                    >
                      Print Labels
                    </button>
                  </div>
                </Stack>
              </tr>
            ))}
          </thead>
          <tbody>
            <tr>
              <td colSpan={3}>
                <Table
                  {...getTableProps()}
                  className="custom-header-css add-move-shipment-table"
                >
                  <thead className="table-light table-head">
                    {headerGroups.map((headerGroup, i) => (
                      <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                        {rows.length ? <th></th> : null}
                        {headerGroup.headers.map((column, i) => {
                          return (
                            <th key={i} {...column.getHeaderProps()}>
                              <div
                                style={{ minHeight: "unset" }}
                                {...(column.Header == "Code" ||
                                  column.Header == "Address" ||
                                  column.Header == "Action"
                                  ? null
                                  : column.getSortByToggleProps())}
                              >
                                {column.render("Header").toUpperCase()}
                                {generateSortingIndicator(column)}
                              </div>
                            </th>
                          )
                        })}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {rows && rows?.length > 0 ? (
                      rows.map((row, i) => {
                        prepareRow(row)
                        return (
                          <tr key={i} {...row.getRowProps()}>
                            {isAllowHideSection ? <td></td> :
                              <td>
                                <CancelIcon
                                  onClick={() => {
                                    // cancleSingleProduct(row, shipmentIndex)
                                    handleModelCofirmation(row, shipmentIndex)
                                  }}
                                  style={{ cursor: "pointer" }}
                                />
                              </td>
                            }
                            {row.cells.map((cell, i) => {
                              return (
                                <td
                                  style={{ fontSize: 12 }}
                                  key={i}
                                  {...cell.getCellProps()}
                                >
                                  {cell.render("Cell")}
                                </td>
                              )
                            })}
                          </tr>
                        )
                      })
                    ) : (
                      <tr>
                        <td colSpan="9" className="no-found-data">
                          No Data Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </td>
            </tr>
          </tbody>
        </Table>
      </div >
    </>
  )
};

export default ExistingShipmentsTable;
