export function generateOrderId(length) {
  var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"
  var orderId = ""

  for (var i = 0; i < length; i++) {
    var randomIndex = Math.floor(Math.random() * characters.length)
    orderId += characters.charAt(randomIndex)
  }
  return orderId
}

export const generateMonthNames = () => {
  const monthNames = []
  const currentDate = new Date()
  for (let month = 0; month < 12; month++) {
    currentDate.setMonth(month)
    const monthName = currentDate.toLocaleString("default", {month: "long"})
    monthNames.push(monthName)
  }
  return monthNames
}

export const formatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based in JS
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
};


export const getPickupDay = () => {
  // Get the current date and time in CET/CEST
  const now = new Date();
  const nowCET = new Date(now.toLocaleString("en-US", {
    timeZone: "Europe/Berlin", // Berlin follows CET/CEST
    hour12: false,
  }));

  // Get the current hour in CET time
  const currentHour = nowCET.getHours();

  let startTime, endTime;

  // If the current time is before 12:00 noon, pickup is today
  if (currentHour < 12) {
    // Start time is the current time, end time is 17:00 today
    startTime = nowCET;
    endTime = new Date(nowCET);
    endTime.setHours(17, 0, 0, 0); // Set end time to 17:00:00 today
  } else {
    // Pickup is tomorrow, start at 08:00 and end at 17:00 tomorrow
    startTime = new Date(nowCET);
    startTime.setDate(nowCET.getDate() + 1); // Move to the next day
    startTime.setHours(8, 0, 0, 0); // Set start time to 08:00:00

    endTime = new Date(startTime);
    endTime.setHours(17, 0, 0, 0); // Set end time to 17:00:00
  }

  return {
    startTime,
    endTime
  };
}
export const getPickupAndDeliveryDays = () => {
  // Utility function to check if a day is a weekend (Saturday or Sunday)
  const isWeekend = (date) => {
    const day = date.getDay();
    return day === 0 || day === 6; // 0 = Sunday, 6 = Saturday
  };

  // Utility function to move date to the next working day if it's a weekend
  const moveToNextWorkingDay = (date) => {
    while (isWeekend(date)) {
      date.setDate(date.getDate() + 1);
    }
    return date;
  };

  // Get the current date and time in CET/CEST
  const now = new Date();
  let nowCET = new Date(now.toLocaleString("en-US", {
    timeZone: "Europe/Berlin",
    hour12: false,
  }));

  // Get the current hour in CET time
  const currentHour = nowCET.getHours();

  let preparationDay = new Date(nowCET);

  // If booking before 12 PM CET, preparation is on the same day
  if (currentHour < 12) {
    preparationDay.setDate(preparationDay.getDate());
  } else {
    preparationDay.setDate(preparationDay.getDate() + 1);
  }

  // Ensure preparationDay is a working day
  preparationDay = moveToNextWorkingDay(preparationDay);

  // Calculate pickup day
  let pickupDay = new Date(preparationDay);
  pickupDay.setDate(preparationDay.getDate() + 1);

  // Ensure pickupDay is a working day
  pickupDay = moveToNextWorkingDay(pickupDay);

  // Calculate delivery day
  let deliveryDay = new Date(pickupDay);
  deliveryDay.setDate(pickupDay.getDate() + 1);

  // Ensure deliveryDay is a working day
  deliveryDay = moveToNextWorkingDay(deliveryDay);

  // Set the time windows
  const setTimeWindow = (date) => {
    const start = new Date(date);
    start.setHours(8, 0, 0, 0);

    const end = new Date(date);
    end.setHours(17, 0, 0, 0);

    return {start, end};
  };

  return {
    preparation: setTimeWindow(preparationDay),
    pickup: setTimeWindow(pickupDay),
    delivery: setTimeWindow(deliveryDay),
  };
};
