import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { useMemo, useState } from "react"
const PrintLabelModel = ({ show, tittle, onCloseClick, pdfBlob }, args) => {
    const [url, setUrl] = useState('');

    const finalURL = useMemo(() => {
        if (pdfBlob) {
            if (pdfBlob.includes('.pdf')) {
                // setUrl(pdfBlob);
                return pdfBlob;
            } else {
                const objectUrl = `data:application/pdf;base64,${pdfBlob}`;
                // setUrl(objectUrl);
                return objectUrl;
                // return () => URL.revokeObjectURL(url);
            }
        }
        else {
            setUrl('')
        }
    }, [pdfBlob]);

    return (
        <div>
            <Modal isOpen={show} toggle={onCloseClick} size='xl' centered={true} backdrop={true} {...args}>
                <ModalHeader toggle={onCloseClick}>{tittle}</ModalHeader>
                <ModalBody>
                    <div className='mt-2'>
                        <iframe
                            src={finalURL} // url
                            width={'100%'}
                            height={600}
                        />
                    </div>
                </ModalBody>
            </Modal>
        </div>
    );
}

export default PrintLabelModel;