import React, { useState, useEffect, useContext } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  Modal,
  ModalHeader,
  ModalFooter,
} from "reactstrap"
import ClickAwayListener from "react-click-away-listener"
import * as Yup from "yup"
import { useFormik } from "formik"
import { toast } from "react-toastify"
import { useSelector, useDispatch } from "react-redux"
import withRouter from "components/Common/withRouter"
import Breadcrumb from "../../components/Common/Breadcrumb"
import avatar from "../../assets/images/users/avatar-1.jpg"
// actions
import { AuthContext } from "context/AuthContext"
import {
  UPDATE_IMAGE_MUTATION,
  UPDATE_PASSWORD_MUTATION,
  UPDATE_USER_PROFILE,
} from "../../graphql/mutations/user"
import useCustomMutation from "../../graphql/hooks/useCustomMutation"
import { useMutation } from "@apollo/client"
import useCustomQuery from "../../graphql/hooks/useCustomQuery"
import { GET_USER } from "../../graphql/queries/user"

const UserProfile = () => {
  const { user, dispatch } = useContext(AuthContext)
  const [email, setemail] = useState(user.email)
  const [name, setname] = useState(user.fullName)
  const [isPassword, setIsPassword] = useState(false)
  const [oldPasswordShow, setOldPasswordShow] = useState(false)
  const [newPasswordShow, setNewPasswordShow] = useState(false)
  const [confirmPasswordShow, setConfirmPasswordShow] = useState(false)
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [oldpassword, setOldPassword] = useState("")
  const currentUserId = JSON.parse(localStorage.getItem('authUser'))
  var { loading, error, data: userData, refetch } = useCustomQuery(GET_USER, {
    variables: { getUserByIdId: currentUserId._id },
  })

  const handlePasswordChange = e => {
    setPassword(e.target.value)
  }
  const handleOldPasswordChange = e => {
    setOldPassword(e.target.value)
  }
  const handleConfirmPasswordChange = e => {
    setConfirmPassword(e.target.value)
  }

  const [UpdatePassword, { loading: UpdatePasswordInput }] = useCustomMutation(
    UPDATE_PASSWORD_MUTATION
  )

  const Updatepassword = () => {
    const input = {
      email: email,
      password: password,
      currentPassword: oldpassword,
    }
    if (oldpassword === password) {
      toast.warn("Change New Password")
    } else {
      if (password !== confirmPassword) {
        toast.warn("Passwords does not match")
      } else {
        UpdatePassword("UpdatePassword", { input: input })
          .then(user => {
            if (user.success === true) {
              toast.success(user.message)
              setOldPassword("")
              setPassword("")
              setConfirmPassword("")
              setIsPassword(false)
            } else {
              toast.warn(user.message)
            }
          })
          .catch(err => {
            console.error(err)
          })
      }
    }
  }

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      password: password || "",
      oldpassword: oldpassword || "",
      confirmPassword: confirmPassword || "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required("Please Enter Password")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#.])[A-Za-z\d@$!%*?&#.]{8,}$/,
          "Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one digit, and one special character"
        ),
    }),
    confirmPassword: Yup.string()
      .required("Please Confirm Password")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
    onSubmit: async values => {
      await Updatepassword()
    },
  })

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title={process.env.REACT_APP_NAME} breadcrumbItem="Profile" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <p className="mb-1">
                          E-mail:<br></br> {email}
                        </p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Card>
            <CardBody>
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <div className="mb-3">
                  <Label className="form-label"> Old Password</Label>
                  <div className="input-group auth-pass-inputgroup">
                    <Input
                      name="oldPassword"
                      type={oldPasswordShow ? "text" : "password"}
                      placeholder="Enter Old Password"
                      onChange={handleOldPasswordChange}
                      onBlur={validation.handleBlur}
                      value={oldpassword}
                      invalid={
                        validation.touched.oldPassword &&
                          validation.errors.oldPassword
                          ? true
                          : false
                      }
                    />
                    <button
                      onClick={() => setOldPasswordShow(!oldPasswordShow)}
                      className="btn btn-light "
                      type="button"
                      id="oldPassword"
                    >
                      {oldPasswordShow ? (
                        <i className="mdi mdi-eye-outline"></i>
                      ) : (
                        <i className="mdi mdi-eye-off-outline"></i>
                      )}
                    </button>
                    {validation.touched.password &&
                      validation.errors.password ? (
                      <FormFeedback type="invalid">
                        {validation.errors.password}
                      </FormFeedback>
                    ) : null}
                  </div>
                </div>
                <div className="mb-3">
                  <Label className="form-label"> New Password</Label>
                  <div className="input-group auth-pass-inputgroup">
                    <Input
                      name="password"
                      type={newPasswordShow ? "text" : "password"}
                      placeholder="Enter New Password"
                      onChange={handlePasswordChange}
                      value={password}
                      onBlur={validation.handleBlur}
                      invalid={
                        validation.touched.password &&
                          validation.errors.password
                          ? true
                          : false
                      }
                    />
                    <button
                      onClick={() => setNewPasswordShow(!newPasswordShow)}
                      className="btn btn-light "
                      type="button"
                      id="newPassword"
                    >
                      {newPasswordShow ? (
                        <i className="mdi mdi-eye-outline"></i>
                      ) : (
                        <i className="mdi mdi-eye-off-outline"></i>
                      )}
                    </button>
                    {validation.touched.password &&
                      validation.errors.password ? (
                      <FormFeedback type="invalid">
                        {validation.errors.password}
                      </FormFeedback>
                    ) : null}
                  </div>
                </div>
                <div className="mb-3">
                  <Label className="form-label">
                    {" "}
                    Confirm New Password
                  </Label>
                  <div className="input-group auth-pass-inputgroup">
                    <Input
                      name="confirmPassword"
                      type={confirmPasswordShow ? "text" : "password"}
                      placeholder="Re-Enter New Password"
                      onChange={handleConfirmPasswordChange}
                      value={confirmPassword}
                      onBlur={validation.handleBlur}
                      invalid={
                        validation.touched.confirmPassword &&
                          validation.errors.confirmPassword
                          ? true
                          : false
                      }
                    />
                    <button
                      onClick={() =>
                        setConfirmPasswordShow(!confirmPasswordShow)
                      }
                      className="btn btn-light "
                      type="button"
                      id="confirmPassword"
                    >
                      {confirmPasswordShow ? (
                        <i className="mdi mdi-eye-outline"></i>
                      ) : (
                        <i className="mdi mdi-eye-off-outline"></i>
                      )}
                    </button>
                    {validation.touched.confirmPassword &&
                      validation.errors.confirmPassword ? (
                      <FormFeedback type="invalid">
                        {validation.errors.confirmPassword}
                      </FormFeedback>
                    ) : null}
                  </div>
                </div>
                <div className="text-left mt-4">
                  <Button
                    type="submit"
                    color="success"
                  >
                    Change Password
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(UserProfile)
