// src/components/filter.
import React, { useContext, useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  FormFeedback,
  Input,
  InputGroup,
  Label,
  Row,
} from "reactstrap"
import { toast } from "react-toastify"
import {
  CREATE_ROLE_MUTATION,
  UPDATE_ROLE_MUTATION,
} from "../../graphql/mutations/role"
import useCustomMutation from "../../graphql/hooks/useCustomMutation"
import { GET_ROLE } from "../../graphql/queries/role"
import { Link, useNavigate, useParams } from "react-router-dom"
import useCustomQuery from "../../graphql/hooks/useCustomQuery"
import PuffLoading from "components/Loading/PuffLoading"
// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"
import {
  CREATE_USER_MUTATION,
  CREATE_USER_WITH_ORGANIZATION_MUTATION,
} from "../../graphql/mutations/user"
import { GetOwnerOrganization } from "../../graphql/queries/organization"
import { AuthContext } from "context/AuthContext"

function CreateUser({ title, isEdit }) {
  const { user } = useContext(AuthContext)
  const navigate = useNavigate()

  document.title = "Create User Account"
  const [orgName, setOrgName] = useState(null)
  const [passwordShow, setPasswordShow] = useState(false)

  let {
    loading: getOwnerLoading,
    _,
    data,
    execute,
  } = useCustomQuery(GetOwnerOrganization)

  useEffect(() => {
    if (data) {
      setOrgName(data.GetOwnerOrganization.name)
    }
  }, [data])

  const [isOrganizationRegistering, setIsOrganizationRegistering] =
    useState(false)

  // Create Branch
  let [CreateUser, { loading: createUserLoading }] =
    useCustomMutation(CREATE_USER_MUTATION)
  let [CreateUserWithOrganization, { loading: createUserWithOrgLoading }] =
    useCustomMutation(CREATE_USER_WITH_ORGANIZATION_MUTATION)

  //form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      fullName: "",
      email: "",
      password: "",
      domain: "",
      name: orgName,
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Email"),
      fullName: Yup.string().required("Please Enter Full name"),
      password: Yup.string()
        .required("Please Enter Password")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
          "Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one digit, and one special character"
        ),
      domain: Yup.string().when("isRequired", {
        is: isOrganizationRegistering ? true : false,
        then: Yup.string().required("Domain is required"),
        otherwise: Yup.string(),
      }),
      name: Yup.string().when("isRequired", {
        is: isOrganizationRegistering ? true : false,
        then: Yup.string().required("Domain Name is required"),
        otherwise: Yup.string(),
      }),
    }),
    onSubmit: values => {
      const input = {
        fullName: values.fullName.trim().toLowerCase(),
        email: values.email.trim().toLowerCase(),
        password: values.password,
        byAdmin: true,
      }
      if (isOrganizationRegistering) {
        if (!values.name || !values.domain) {
          toast.error("Organization name and domain is required")
          return
        }
        input.organization = {
          name: values.name.trim().toLowerCase(),
          domain: values.domain.trim().toLowerCase(),
        }
      }

      if (isOrganizationRegistering) {
        CreateUserWithOrganization("CreateUserWithOrganization", { input })
          .then(user => {
            if (user) {
              toast.success("Account Created with organization !")
              setTimeout(() => {
                navigate("/users")
              }, 1000)
            }
          })
          .catch(err => {
            console.error(err)
          })
      } else {
        input.organizationNameOrDomain = values.name
        CreateUser("CreateUser", { input })
          .then(user => {
            if (user) {
              toast.success("Account Created Successfully !")
              setTimeout(() => {
                navigate("/users")
              }, 1000)
            }
          })
          .catch(err => {
            console.error(err)
          })
      }
    },
  })

  return (
    <div className="page-content">
      {(getOwnerLoading || createUserLoading || createUserWithOrgLoading) && (
        <PuffLoading />
      )}
      <div className="container-fluid">
        <Breadcrumbs title="Branch" breadcrumbItem={title} />
        <Card>
          <Row>
            <Col lg={12}>
              <div
                className="p-md-5 p-4"
                style={{ minHeight: "100vh !important" }}
              >
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="my-auto">
                      <div>
                        <h5 className="text-primary">
                          Register new user account
                        </h5>
                      </div>

                      <div className="mt-4">
                        <Form
                          className="form-horizontal"
                          onSubmit={e => {
                            e.preventDefault()
                            validation.handleSubmit()
                            return false
                          }}
                        >
                          <div className="mb-3">
                            <Label className="form-label">Full Name</Label>
                            <Input
                              name="fullName"
                              type="text"
                              placeholder="Enter Full Name"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.fullName || ""}
                              invalid={
                                validation.touched.fullName &&
                                  validation.errors.fullName
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.fullName &&
                              validation.errors.fullName ? (
                              <FormFeedback type="invalid">
                                {validation.errors.fullName}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">Email</Label>
                            <Input
                              id="email"
                              name="email"
                              className="form-control"
                              placeholder="Enter email"
                              type="email"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              invalid={
                                validation.touched.email &&
                                  validation.errors.email
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.email &&
                              validation.errors.email ? (
                              <FormFeedback type="invalid">
                                {validation.errors.email}
                              </FormFeedback>
                            ) : null}
                          </div>

                          <div className="mb-3">
                            <Label className="form-label">Password</Label>
                            <div className="input-group auth-pass-inputgroup">
                              <Input
                                name="password"
                                type={passwordShow ? "text" : "password"}
                                placeholder="Enter password"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.password || ""}
                                invalid={
                                  validation.touched.password &&
                                    validation.errors.password
                                    ? true
                                    : false
                                }
                              />
                              <button
                                onClick={() => setPasswordShow(!passwordShow)}
                                className="btn btn-light "
                                type="button"
                                id="password-addon"
                              >
                                {passwordShow ? (
                                  <i className="mdi mdi-eye-outline"></i>
                                ) : (
                                  <i className="mdi mdi-eye-off-outline"></i>
                                )}
                              </button>
                              {validation.touched.password &&
                                validation.errors.password ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.password}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </div>

                          <div className="mb-3">
                            <Label className="form-label">
                              Organization Name
                            </Label>
                            <Input
                              name="name"
                              type="text"
                              placeholder="Enter name"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.name || ""}
                              invalid={
                                validation.touched.name &&
                                  validation.errors.name
                                  ? true
                                  : false
                              }
                              disabled
                            />
                            {validation.touched.name &&
                              validation.errors.name ? (
                              <FormFeedback type="invalid">
                                {validation.errors.name}
                              </FormFeedback>
                            ) : null}
                          </div>

                          {/* <div className="mb-3">
                            <Label className="form-label text-primary">
                               register organization as well ?
                            </Label>
                            <div className="form-check form-switch form-switch-lg mb-3">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="customSwitchsizelg"
                                checked={isOrganizationRegistering}
                                onChange={() =>
                                  setIsOrganizationRegistering(
                                    !isOrganizationRegistering
                                  )
                                }
                              />
                              <label
                                className="form-check-label"
                                htmlFor="customSwitchsizelg"
                              >
                                Register Organization
                              </label>
                            </div>
                          </div>

                          {isOrganizationRegistering && (
                            <>

                              <div className="mb-3">
                                <Label className="form-label">
                                  Organization Domain
                                </Label>
                                <Input
                                  name="domain"
                                  type="text"
                                  placeholder="Enter domain"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.domain || ""}
                                  invalid={
                                    validation.touched.domain &&
                                    validation.errors.domain
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.domain &&
                                validation.errors.domain ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.domain}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </>
                          )} */}

                          <div className="mt-4 d-grid">
                            <button
                              className="btn btn-primary waves-effect waves-light "
                              type="submit"
                            >
                              Create User
                            </button>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Card>
      </div>
    </div>
  )
}
CreateUser.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default CreateUser
