import React, { useState } from "react"
import { Link, redirect, useNavigate } from "react-router-dom"
import {
  Col,
  Container,
  Form,
  Row,
  Input,
  Label,
  FormFeedback,
} from "reactstrap"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

import { CHANGE_PASSWORD_MUTATION } from "../../graphql/mutations/user"
import useCustomMutation from "../../graphql/hooks/useCustomMutation"
import { toast } from "react-toastify"
import PuffLoading from "components/Loading/PuffLoading"
import AuthFooter from "./AuthFooter"
import { useLayoutEffect } from "react"
import { LINKS } from "utils/links"

const ChangePassword = () => {
  const [user, setUser] = useState(
    sessionStorage.getItem("user")
      ? JSON.parse(sessionStorage.getItem("user"))
      : {}
  )

  useLayoutEffect(() => {
    if (!user.email || !user?.isVerified === true || !user?.otp) {
      window.location.replace("/login")
    }
  }, [])

  const [passwordShow, setPasswordShow] = useState(false)
  const [passwordConfirmShow, setPasswordConfirmShow] = useState(false)
  var [ResetPassword, { loading, error }] = useCustomMutation(
    CHANGE_PASSWORD_MUTATION
  )

  const navigate = useNavigate()

  //meta title
  document.title = "Login"

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: user.email,
      password: "",
      otpCode: user.otp,
      confirm_password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your email"),
      otpCode: Yup.string().required(),
      password: Yup.string()
        .required("Please Enter Your Password")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
          "Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one digit, and one special character"
        ),
      confirm_password: Yup.string()
        .required("Please Confirm Password")
        .oneOf([Yup.ref("password"), null], "Passwords must match"),
    }),
    onSubmit: values => {

      const input = {
        email: user.email,
        password: values.password,
        otpCode: user.otp,
      }

      ResetPassword("ResetPassword", { input })
        .then(response => {
          if (response) {
            if (response) {
              toast.success(response.message)
              sessionStorage.removeItem("user")
              setTimeout(() => {
                navigate("/login")
              }, 1000)
            }
          }
        })
        .catch(err => {
          console.error(err)
        })
    },
  })
  return (
    <React.Fragment>
      {loading && <PuffLoading />}
      {user?.isVerified && (
        <div>
          <Container fluid className="p-0 login-container-center">
            <Row className="g-0 login-container">

              <Col >
                <div className="auth-full-page-content p-md-5 p-4">
                  <div className="w-100">
                    <div className="d-flex flex-column h-100">
                      <div className="my-auto">
                        <div>
                          <h5 className="text-primary">Change Password</h5>
                        </div>

                        <div className="mt-4">
                          <Form
                            className="form-horizontal"
                            onSubmit={e => {
                              e.preventDefault()
                              validation.handleSubmit()
                              return false
                            }}
                          >
                            <div className="mb-3">
                              <Label className="form-label">New Password</Label>
                              <div className="input-group auth-pass-inputgroup">
                                <Input
                                  name="password"
                                  value={validation.values.password || ""}
                                  type={passwordShow ? "text" : "password"}
                                  placeholder="Enter Password"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  invalid={
                                    validation.touched.password &&
                                      validation.errors.password
                                      ? true
                                      : false
                                  }
                                />
                                <button
                                  onClick={() => setPasswordShow(!passwordShow)}
                                  className="btn btn-light "
                                  type="button"
                                  id="password-addon"
                                >
                                  {passwordShow ? (
                                    <i className="mdi mdi-eye-outline"></i>
                                  ) : (
                                    <i className="mdi mdi-eye-off-outline"></i>
                                  )}
                                </button>
                                {validation.touched.password &&
                                  validation.errors.password ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.password}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">
                                Confirm Password
                              </Label>
                              <div className="input-group auth-pass-inputgroup">
                                <Input
                                  name="confirm_password"
                                  value={
                                    validation.values.confirm_password || ""
                                  }
                                  type={
                                    passwordConfirmShow ? "text" : "password"
                                  }
                                  placeholder="Confirm Password"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  invalid={
                                    validation.touched.confirm_password &&
                                      validation.errors.confirm_password
                                      ? true
                                      : false
                                  }
                                />
                                <button
                                  onClick={() =>
                                    setPasswordConfirmShow(!passwordConfirmShow)
                                  }
                                  className="btn btn-light "
                                  type="button"
                                  id="password-addon"
                                >
                                  {passwordConfirmShow ? (
                                    <i className="mdi mdi-eye-outline"></i>
                                  ) : (
                                    <i className="mdi mdi-eye-off-outline"></i>
                                  )}
                                </button>
                                {validation.touched.confirm_password &&
                                  validation.errors.confirm_password ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.confirm_password}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </div>

                            <div className="mt-3 d-grid">
                              <button
                                className="btn btn-primary btn-block "
                                type="submit"
                              >
                                Change Passowrd
                              </button>
                            </div>
                          </Form>
                        </div>
                      </div>

                      <div className="mt-2 text-center">
                        <AuthFooter />
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </React.Fragment>
  )
}

export default ChangePassword
