// src/components/filter.
import React, { useEffect, useMemo, useState } from "react"

//import components
import PuffLoading from "components/Loading/PuffLoading"
import { Col, Row } from "reactstrap"
import Pagination from "../../components/pagination"
import { useLazyQuery, useMutation } from "@apollo/client"
import PaginatedTable from "components/Table/PaginatedTable"
import OvalLoading from "components/Loading/OvalLoading"
import { GET_SUPPLIER_NEW_PRICES } from "graphql/queries/product"
import { STATUS, SUPPLIERS } from "utils/constants"
import { UPDATE_SUPPLIER_PRICE } from "graphql/mutations/product"
import { toast } from "react-toastify"

function NewPrices() {
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(25)
  const [totalCount, setTotalCount] = useState(0)
  const [supplier, setSupplier] = useState(SUPPLIERS[0])
  const [productData, setProductsData] = useState([])
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("")

  const [UpdateSupplierPrice] = useMutation(UPDATE_SUPPLIER_PRICE)
  const [search, { loading: searchLoading, __, data, error, refetch }] =
    useLazyQuery(GET_SUPPLIER_NEW_PRICES, {
      onCompleted: data => {
        const totalCount = data?.GetSupplierNewPrices?.totalCount
        if (page > Math.ceil(totalCount / perPage)) {
          setPage(1)
        }
        setProductsData(data?.GetSupplierNewPrices?.products || [])
        setTotalCount(totalCount)
      },
    })

  // First time load all data with pagination
  useEffect(() => {
    if (debouncedSearchTerm.trim() === "") {
      search({
        variables: {
          page,
          perPage,
          supplier,
        },
      })
    }
  }, [debouncedSearchTerm, page, perPage])

  if (error) {
    console.log(error)
  }

  const columns = useMemo(
    () => [
      {
        Header: "SKU",
        accessor: "sku",
      },
      {
        Header: "Title",
        accessor: "name",
      },
      {
        Header: "Stock Status",
        accessor: "status",
      },
      {
        Header: "Price",
        accessor: "price",
      },
      {
        Header: "New Price",
        accessor: "newPrice",
      },
      {
        Header: "isApproved",
        accessor: "isPriceApproved",
        Cell: (status) => {
          return (
            <div className="branch_status">
              <select
                className="form-select"
                style={{ minWidth: 140, textTransform: "capitalize" }}
                data-id={status.row.original._id}
                onChange={e =>
                  UpdateSupplierPrice({
                    variables: {
                      sku: status.cell.row.original.sku,
                      input: { status: e.target.value },
                    }
                  })
                    .then(res => {
                      refetch()
                      toast.success("Status Updated!")
                    })
                }
              >
                {STATUS
                  ?.map((oStatus, i) => (
                    <option
                      key={i}
                      value={oStatus}
                      selected={
                        oStatus?.toLowerCase() ===
                        status?.value?.toLowerCase()
                      }
                    >
                      {oStatus}
                    </option>
                  ))}
              </select>
            </div >
          )
        },
      },
    ],
    [productData]
  )

  document.title = "New Prices | Client Communication"

  return (
    <div className="page-content">
      {searchLoading && <PuffLoading />}
      <div className="container-fluid">
        <div className="mt-2">
          <Row className="mb-3">
            <Col md={4} >
              <label>Select Supplier</label>
              <div>
                <select
                  className="form-select"
                  style={{ minWidth: 140, textTransform: "capitalize" }}
                  value={supplier}
                  onChange={e => {
                    setSupplier(e.target.value);
                    search({
                      variables: {
                        page: 1,
                        perPage: 25,
                        supplier: e.target.value,
                      },
                    })
                  }
                  }
                >
                  {SUPPLIERS
                    ?.map((value, i) => (
                      <option
                        key={i}
                        value={value}
                        selected={
                          value?.toUpperCase() ===
                          supplier?.toUpperCase()
                        }
                      >
                        {value}
                      </option>
                    ))}
                </select>
              </div>
            </Col>
            <Col md={8}>
              <Pagination
                totalRows={totalCount}
                pageChangeHandler={setPage}
                rowsPerPage={perPage}
                setRowsPerPage={setPerPage}
                currentPage={page}
              />
            </Col>
          </Row>


          <div className="position-relative">
            {searchLoading && <OvalLoading />}
            <PaginatedTable
              columns={columns}
              data={productData || []}
              isLoading={searchLoading}
            />
          </div>
          <Pagination
            totalRows={totalCount}
            pageChangeHandler={setPage}
            rowsPerPage={perPage}
            setRowsPerPage={setPerPage}
            currentPage={page}
          />
        </div>
      </div>
    </div>
  )
}

export default NewPrices
