// src/components/filter.
import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "../../components/Common/TableContainer"
import { GET_ROLES, GET_ROLES_OF_ORGANIZATION } from "../../graphql/queries/role"
import useCustomQuery from "../../graphql/hooks/useCustomQuery"
import PuffLoading from "components/Loading/PuffLoading"
import { Button, Col, Row } from "reactstrap"
import { Link } from "react-router-dom"
import { confirmAlert } from "react-confirm-alert"
import useCustomMutation from "../../graphql/hooks/useCustomMutation"
import {
  DELETE_ROLE_MUTATION,
  UPDATE_ROLE_MUTATION,
} from "../../graphql/mutations/role"
import { toast } from "react-toastify"
import moment from "moment"

function Role() {
  const [DeleteRole, { _, __ }] = useCustomMutation(DELETE_ROLE_MUTATION, {
    refetchQueries: [{ query: GET_ROLES_OF_ORGANIZATION }],
  })

  const deleteRole = id => {
    confirmAlert({
      title: "Confirm",
      message: "Are you sure you want to delete this role?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            DeleteRole("DeleteRole", {
              deleteRoleId: id,
            })
              .then(role => {
                toast.success("Role Deleted successfully!")
              })
              .catch(err => {
                console.error("Error deleting role:", err)
              })
          },
        },
        {
          label: "No",
          onClick: () => { },
        },
      ],
    })
  }

  const [roleData, setRoleData] = useState([])

  const [UpdateRole, { ___, ____: mutationError }] = useCustomMutation(
    UPDATE_ROLE_MUTATION,
    {
      refetchQueries: [{ query: GET_ROLES_OF_ORGANIZATION }],
    }
  )

  const handleRoleCheckboxChange = e => {
    const input = {
      isDisabled: !e.target.checked,
      updatedBy: JSON.parse(localStorage.getItem("authUser"))._id,
    }
    UpdateRole("UpdateRole", { updateRoleId: e.target.id, input })
      .then(role => {
        if (role) {
          toast.success("Role Updated Successfully !")
          const roles = roleData?.map(role => {
            if (role._id === e.target.id) {
              return {
                ...role,
                isDisabled: !e.target.checked,
              }
            } else {
              return role
            }
          })
          setRoleData(roles)
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  const { loading, ____, data, execute } = useCustomQuery(GET_ROLES_OF_ORGANIZATION)

  useEffect(() => {
    if (data) {
      setRoleData(data?.GetRolesOfOrganization)
    }
  }, [data])

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Status",
        accessor: "isDisabled",
        Cell: role => {
          return (
            <div className="form-check form-switch form-switch-md mb-3">
              <input
                type="checkbox"
                className="form-check-input"
                id={role.row.original._id}
                checked={role.value === true ? false : true}
                onChange={handleRoleCheckboxChange}
              />
              <label
                className="form-check-label"
                htmlFor={role.row.original._id}
              >
                {role.value ? "Disabled" : "Enabled"}
              </label>
            </div>
          )
        },
      },
      // {
      //   Header: "createdAt",
      //   accessor: "createdAt",
      //   Cell: ({ value }) => {
      //     return (
      //       <span>
      //         {moment(new Date(parseInt(value)))
      //           .startOf("hour")
      //           .fromNow()}
      //       </span>
      //     )
      //   },
      // },
      // {
      //   Header: "updatedAt",
      //   accessor: "updatedAt",
      //   Cell: ({ value }) => {
      //     // return <span>{moment(new Date(parseInt(value))).format("DD/MM/YY hh:mm:ss a")}</span>
      //     return (
      //       <span>
      //         {moment(new Date(parseInt(value)))
      //           .startOf("hour")
      //           .fromNow()}
      //       </span>
      //     )
      //   },
      // },
      // {
      //   Header: "Action",
      //   accessor: "_id",
      //   Cell: ({ value }) => {
      //     return (
      //       <Row>
      //         {/* <Col>
      //           <Link to={"/roles/edit/" + value}>
      //             <Button className="btn btn-secondary waves-effect waves-light btn btn-secondary w-100">Edit</Button>
      //           </Link>
      //         </Col> */}
      //         <Col>
      //           <Button
      //             className="btn btn-danger w-100"
      //             onClick={() => deleteRole(value)}
      //           >
      //             Delete
      //           </Button>
      //         </Col>
      //       </Row>
      //     )
      //   },
      // },
    ],
    [roleData]
  )

  document.title = "Roles"

  return (
    <div className="page-content">
      {loading && <PuffLoading />}
      <div className="container-fluid">
        <Breadcrumbs title="Tables" breadcrumbItem="Roles" />
        {/* <Link to="/roles/create">
          <Button
            color="primary"
            className="btn btn-primary waves-effect waves-light"
            style={{ position: "absolute", left: "auto", right: 25 }}
          >
            Add
          </Button>
        </Link> */}

        <TableContainer
          columns={columns}
          data={roleData || []}
          isGlobalFilter={true}
          isAddOptions={false}
          customPageSize={10}
          className="custom-header-css"
        />
      </div>
    </div>
  )
}
Role.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default Role
