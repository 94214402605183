import { gql } from '@apollo/client';

const CREATE_SHIPMENT = gql`
  mutation createShipment($input: [CreateShipmentInput]!) {
    createShipment(input: $input) {
     shipmentIds
     deliveryPartners
    }
  }
`;
export {
    CREATE_SHIPMENT
}