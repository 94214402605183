import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
} from "reactstrap";
import Breadcrumb from "../../components/Common/Breadcrumb";
import avatar from "../../assets/images/users/avatar-1.jpg";
// import { GET_USER } from "graphql/queries/user";
// import useCustomQuery from "../../graphql/hooks/useCustomQuery";
// import useCustomMutation from "../../graphql/hooks/useCustomMutation";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify"
import moment from "moment";
import { GET_ORGANIZATION_BY_ID } from "../../graphql/queries/organization";
import { UPDATE_ORGANIZATION } from "../../graphql/mutations/organization";

function EditOrganization({ isEdit, isView }) {
  const params = useParams();
  const [idx, setidx] = useState(1);
  const [userId, setUserId] = useState();
  const [userData, setUserData] = useState([])
  const [domain, setDomain] = useState("")
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [loyaltyPoints, setLoyaltyPoints] = useState()
  let navigate = useNavigate();


  const [socialPlatform, setSocialPlatform] = useState([{ id: 1, value: "" }])

  const [platform, setPlatform] = useState("");


  var [UpdateUser, { loading, error }] = useCustomMutation(
    UPDATE_ORGANIZATION
  )

  var { loading, error, data, execute } = useCustomQuery(GET_ORGANIZATION_BY_ID, {
    variables: { organizationId: params.orgId },
  })


  useEffect(() => {

    if (data != null) {
      const baseData = data?.Organization
      setName(baseData?.name)
      setDomain(baseData?.domain)

    }

  }, [data])




  const updateProfile = (e) => {
    e.preventDefault();
    if (name == null || name == "") {
      return toast.error("Organization name can't be empty")
    } else {
      const allInputs = {
        name: name,
        domain: domain,

      }


      UpdateUser("UpdateUser", { input: allInputs, updateOrganizationId: params.orgId }).then(user => {
        toast.success("Organization Updated Successfully!")
        navigate(-1)
      })
        .catch(err => {
          toast.error(err)
        })
    }



  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}

          {/* <Breadcrumb title="Skote" breadcrumbItem="Organization" /> */}

          <Row>
            <Col lg="12">
              {/* {error && error ? <Alert color="danger">{error}</Alert> : null}
              {success ? <Alert color="success">{success}</Alert> : null} */}


            </Col>
          </Row>

          <h4 className="card-title mb-4">Edit Organization</h4>

          <Card>
            <CardBody>
              <Form
                className="form-horizontal"
                onSubmit=""
              >
                <div className="form-group">
                  <Label className="form-label">Name</Label>
                  <Input
                    name="fullname"
                    className="form-control"
                    placeholder="Enter Name"
                    type="text"
                    disabled={isView}
                    value={name}
                    onChange={(e) => setName(e.target.value)}

                  />

                  <Label className="form-label">Domain</Label>
                  <Input
                    name="domain"
                    className="form-control"
                    placeholder="Enter Domain"
                    disabled={isView}
                    type="text"
                    value={domain}
                    onChange={(e) => setDomain(e.target.value)}
                  />





                  {/* <Input name="idx" value={idx} type="hidden" /> */}
                </div>
                <div className="text-center mt-4">
                  {isView ? (null) : (
                    <Button onClick={updateProfile} type="submit" color="danger">
                      Update Profile
                    </Button>
                  )}

                </div>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EditOrganization
