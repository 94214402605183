import React from "react"
import { Link, useNavigate } from "react-router-dom"
import {
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap"
// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"


import AuthFooter from "./AuthFooter"
import { VERIFY_OTP_MUTATION } from "../../graphql/mutations/user"
import useCustomMutation from "../../graphql/hooks/useCustomMutation"
import PuffLoading from "components/Loading/PuffLoading"
import { toast } from "react-toastify"
import InputMask from "react-input-mask"
import { useLayoutEffect } from "react"
import { useState } from "react"

const VerifyOtp = () => {
  const [user, setUser] = useState(
    sessionStorage.getItem("user")
      ? JSON.parse(sessionStorage.getItem("user"))
      : {}
  )

  useLayoutEffect(() => {
    if (!user.email) {
      window.location.replace("/login")
    }
  }, [])

  const [otp, setOtp] = useState("")

  document.title = "Verify Otp"

  const [VerifyOtp, { loading, error }] = useCustomMutation(VERIFY_OTP_MUTATION)
  const navigate = useNavigate()

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: user?.email,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Please Enter Your Email")
        .matches(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          "Please enter a valid email address"
        ),
    }),
    onSubmit: values => {
      if (!/^[0-9]+$/.test(otp)) {
        toast.error("Enter complete otp")
        return
      }
      const input = {
        email: values.email,
        otpCode: otp,
      }
      VerifyOtp("VerifyOtp", { input })
        .then(response => {
          if (response) {
            if (response && response.success === true) {
              toast.success(response.message)
              sessionStorage.setItem(
                "user",
                JSON.stringify({
                  email: user.email,
                  isVerified: true,
                  otp: response.user.otpCode,
                })
              )
              setTimeout(() => {
                navigate("/change-password")
              }, 1000)
            } else {
              toast.error(response.message)
            }
          }
        })
        .catch(err => {
          console.error(err)
        })
    },
  })
  return (
    <React.Fragment>
      {loading && <PuffLoading />}
      {user?.email && (
        <div>
          <Container fluid className="p-0 login-container-center">
            <Row className="g-0 login-container">
              <Col >
                <div className="auth-full-page-content p-md-5 p-4">
                  <div className="w-100">
                    <div className="d-flex flex-column h-100">
                      <div className="my-auto">
                        <div>
                          <h5 className="text-primary">Verify OTP</h5>
                        </div>

                        <div className="mt-4">
                          <div
                            className="alert alert-success text-center mb-4"
                            role="alert"
                          >
                            Enter the OTP sent to email address!
                          </div>

                          <Form
                            className="form-horizontal"
                            onSubmit={e => {
                              e.preventDefault()
                              validation.handleSubmit()
                              return false
                            }}
                          >
                            <div className="mb-3">
                              <div className="form-group mb-4">
                                <Label for="input-repeat">OTP CODE</Label>
                                <InputMask
                                  mask="999999"
                                  value={otp}
                                  className="form-control input-color"
                                  onChange={e => {
                                    setOtp(e.target.value)
                                  }}
                                ></InputMask>
                              </div>
                            </div>
                            <div>
                              <button
                                className="btn btn-primary w-100"
                                type="submit"
                              >
                                Verify OTP
                              </button>
                            </div>
                          </Form>

                          <div className="mt-3 text-center">
                            <p>
                              Remember It ?{" "}
                              <Link
                                to="/login"
                                className="fw-medium text-primary"
                              >
                                {" "}
                                Sign In here
                              </Link>{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="text-center">
                        <AuthFooter />
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </React.Fragment>
  )
}

export default VerifyOtp
