import React from "react"


import { Col, Container, Row } from "reactstrap"
import AuthFooter from "./AuthFooter"
import { Link } from "react-router-dom"

const EmailVerification = () => {
    //meta title
    document.title = "Email Verification Send";
    return (
        <React.Fragment>
            {localStorage.getItem("verifyEmail") && <div>
                <Container fluid className="p-0 login-container-center">
                    <Row className="g-0 login-container">

                        <Col >
                            <div className="auth-full-page-content p-md-5 p-4">
                                <div className="w-100">
                                    <div className="d-flex flex-column h-100">
                                        <div className="my-auto">
                                            <div className="text-center">
                                                <div className="avatar-md mx-auto">
                                                    <div className="avatar-title rounded-circle bg-light">
                                                        <i className="bx bxs-envelope h1 mb-0 text-primary"></i>
                                                    </div>
                                                </div>
                                                <div className="p-2 mt-4">
                                                    <h4>Verify your email</h4>
                                                    <p>
                                                        We have sent you verification email at{" "}
                                                        <span className="fw-semibold text-success">
                                                            {localStorage.getItem("verifyEmail")}
                                                        </span>
                                                        , Please check it
                                                    </p>
                                                    <div className="mt-4">
                                                        <Link
                                                            to="/login"
                                                            className="btn btn-success w-md"
                                                        >
                                                            Go to Login
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mt-4 mt-md-5 text-center">
                                            <AuthFooter />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            }
        </React.Fragment>
    )
}

export default EmailVerification;
