import React, { useState, useEffect, useCallback } from "react";
import {
  Container,
  Card,
  CardBody,
  Button,
  Label,
  Input,
  Form,
} from "reactstrap";
import Breadcrumb from "../../components/Common/Breadcrumb";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify"
import useCustomMutation from "graphql/hooks/useCustomMutation";
import { useLazyQuery } from "@apollo/client";
import { GET_PRODUCT_BY_ID } from "graphql/queries/product";
import Select from "react-select";
import { UPDATE_PRODUCT } from "graphql/mutations/product";
import PuffLoading from "components/Loading/PuffLoading";

const productStatus = [
  {
    label: "Draft",
    value: "draft"
  },
  {
    label: "Pending",
    value: "pending"
  },
  {
    label: "Private",
    value: "private"
  },
  {
    label: "Publish",
    value: "publish"
  },
]
const productTypes = [
  {
    label: "Simple",
    value: "simple"
  },
  {
    label: "Grouped",
    value: "grouped"
  },
  {
    label: "External",
    value: "external"
  },
  {
    label: "Variable",
    value: "variable"
  },
]

function EditProduct() {
  const params = useParams();
  const [productForm, setProductForm] = useState({
    product_id: "",
    name: '',
    price: 0,
    stock_quantity: 0,
    type: '',
    status: '',
    description: '',
    updatedAt: '',
    wp_updatedAt: ''
  })
  let navigate = useNavigate();

  const [updateProduct, { loading: updateProductLoading }] = useCustomMutation(UPDATE_PRODUCT)

  const [
    getProduct, { loading: productLoading },
  ] = useLazyQuery(GET_PRODUCT_BY_ID, {
    onCompleted: data => {
      setProductForm(data?.GetProductById)
    },
  })

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target
    setProductForm(prev => ({ ...prev, [name]: value }))
  }, [setProductForm])

  const updateProfile = (e) => {
    e.preventDefault();

    const allInputs = {
      product_id: productForm.product_id,
      name: productForm.name,
      slug: productForm.name.toLowerCase().replaceAll(" ", "-"),
      price: parseFloat(productForm.price),
      stock_quantity: parseInt(productForm.stock_quantity),
      type: productForm.type.toLowerCase(),
      status: productForm.status.toLowerCase(),
      description: productForm.description,
    }

    updateProduct("updateProduct", { input: allInputs, updateProductId: params.id })
      .then(product => {
        toast.success("Product Updated Successfully!")
        navigate(-1)
      })
      .catch(err => {
      })
  }

  useEffect(() => {
    getProduct({
      variables: {
        getProductById: params?.id
      },
    })
  }, [params?.id])

  return (
    <React.Fragment>
      <div className="page-content">
        {(productLoading || updateProductLoading) && <PuffLoading />}
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="Stoke Management" breadcrumbItem="Edit Product" />

          <h4 className="card-title mb-4">Product ID #{productForm?.product_id}</h4>
          <Card>
            <CardBody>
              <Form
                className="form-horizontal"
                onSubmit={updateProfile}
              >
                <div className="form-group">
                  <Label className="form-label">Product Name</Label>
                  <Input
                    name="name"
                    className="form-control"
                    placeholder="Enter Product Name"
                    type="text"
                    value={productForm?.name}
                    onChange={handleInputChange}
                    required
                  />
                  {productForm.type !== "external" && <>
                    <Label className="form-label">Stock Quantity</Label>
                    <Input
                      name="stock_quantity"
                      className="form-control"
                      placeholder="Enter Stock Quantity"
                      type="number"
                      value={productForm?.stock_quantity}
                      onChange={handleInputChange}
                      required
                    />
                  </>}
                  <Label className="form-label">Product Price</Label>
                  <Input
                    name="price"
                    className="form-control"
                    placeholder="Enter Product Price"
                    type="number"
                    value={productForm?.price}
                    onChange={handleInputChange}
                    required
                  />
                  <Label className="form-label">Product Status</Label>
                  <Select
                    styles={{
                      singleValue: (provided, state) => ({
                        ...provided,
                        textTransform: "capitalize",
                      })
                    }}
                    options={productStatus}
                    value={{ value: productForm?.status, label: productForm?.status }}
                    name="status"
                    placeholder="Select Product Status"
                    type="text"
                    onChange={(e) => setProductForm((prev => ({ ...prev, status: e.value })))}
                    required
                  />
                  <Label className="form-label">Product Type</Label>
                  <Select
                    styles={{
                      singleValue: (provided, state) => ({
                        ...provided,
                        textTransform: "capitalize",
                      })
                    }}
                    options={productTypes}
                    value={{ value: productForm?.type, label: productForm?.type }}
                    name="type"
                    placeholder="Select Product Type"
                    type="text"
                    onChange={(e) => setProductForm((prev => ({ ...prev, type: e.value })))}
                    required
                  />
                </div>
                <div className="text-center mt-4">
                  <Button type="submit" color="danger">
                    Update Product
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EditProduct
