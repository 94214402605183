// src/components/filter.
import React, { useEffect, useMemo, useState } from "react"

//import components
import PuffLoading from "components/Loading/PuffLoading"
import { Col, Row } from "reactstrap"
import Pagination from "../../components/pagination"
import { useLazyQuery } from "@apollo/client"
import PaginatedTable from "components/Table/PaginatedTable"
import OvalLoading from "components/Loading/OvalLoading"
import { GET_ALL_PRODUCTS } from "graphql/queries/product"
import { toast } from 'react-toastify'
import { DELIVERY_TIMES } from 'utils/deliveryTimes'
import { UPDATE_DELIVERY_TIME } from "graphql/mutations/product"
import useCustomMutation from "graphql/hooks/useCustomMutation"

function ProductInformation() {

  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(25)
  const [totalCount, setTotalCount] = useState(0)
  const [searchTerm, setSearchTerm] = useState("")
  const [productData, setProductsData] = useState([])
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("")

  // Server side searching
  const [search, { loading: searchLoading, __, data, error, refetch }] =
    useLazyQuery(GET_ALL_PRODUCTS, {
      onCompleted: data => {
        const totalCount = data?.GetAllProducts?.totalCount
        if (page > Math.ceil(totalCount / perPage)) {
          setPage(1)
        }
        setProductsData(data?.GetAllProducts?.products || [])
        setTotalCount(totalCount)
      },
    })

  const [updateDeliveryTime] = useCustomMutation(UPDATE_DELIVERY_TIME)

  // First time load all data with pagination
  useEffect(() => {
    if (debouncedSearchTerm.trim() === "") {
      search({
        variables: {
          page: page,
          perPage: perPage,
          search: searchTerm?.trim(),
        },
      })
    }
  }, [debouncedSearchTerm, page, perPage])

  const getProductsHandle = (e) => {
    e.preventDefault()
    search({
      variables: {
        page: page,
        perPage: perPage,
        search: searchTerm?.trim(),
      },
    })
  }

  if (error) {
    console.log(error)
  }

  const columns = useMemo(
    () => [
      {
        Header: "Product ID",
        accessor: "product_id",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "SKU",
        accessor: "sku",
      },
      {
        Header: "Stock Quantity",
        accessor: "stock_quantity",
      },
      {
        Header: "Price",
        accessor: "price",
      },
      // {
      //   Header: "Time Override",
      //   accessor: "isTimeOverride",
      //   Cell: ({ value }) => {
      //     console.log(value);
      //     return (
      //       <span>{value ? 'True' : ''}</span>
      //     )
      //   },
      // },
      {
        Header: "Delivery Time",
        accessor: "estimate",
      },
      {
        Header: "Action",
        accessor: "_id",
        Cell: (id) => {
          return (
            <div className="branch_status" style={{ maxWidth: 300 }}>
              <select
                className="form-select"
                style={{ minWidth: 140, textTransform: "uppercase" }}
                data-id={id.row.original._id}
                onChange={e => {
                  if (!e.target.value) return
                  updateDeliveryTime("updateDeliveryTime",
                    {
                      input: { estimate: e.target.value },
                      id: id.row.original._id,
                    })
                    .then(res => {
                      refetch()
                      toast.success("Delivery Time Updated!")
                    })
                }
                }
              >
                {DELIVERY_TIMES &&
                  DELIVERY_TIMES
                    ?.map((time) => (
                      <option
                        key={time}
                        value={time}
                        selected={
                          time.toLowerCase() ===
                          id.row.original?.estimate?.toLowerCase()
                        }
                      >
                        {time}
                      </option>
                    ))}
              </select>
            </div >
          )
        },
      }
    ],
    [productData]
  )

  document.title = "Product Information | Client Communication"

  return (
    <div className="page-content">
      {searchLoading && <PuffLoading />}
      <div className="container-fluid">
        <div className="mt-2">
          <Row>
            <Col md={4} className="d-flex align-items-center">
              <form
                onSubmit={getProductsHandle}
                className="search-box d-flex align-items-center gap-2 mb-2"
              >
                <div className="position-relative">
                  <label htmlFor="search-bar-0" className="search-label mb-0">
                    <span id="search-bar-0-label" className="sr-only">
                      Enter SKU
                    </span>
                    <input
                      onChange={e => {
                        setSearchTerm(e.target.value)
                      }}
                      id="search-bar-0"
                      type="text"
                      className="form-control"
                      placeholder={`Enter SKU`}
                      value={searchTerm}
                    />
                  </label>
                  <i className="bx bx-search-alt search-icon"></i>
                </div>
                <button type="submit" className="btn btn-primary waves-effect waves-light btn btn-primary">Search</button>
              </form>
            </Col>
            <Col md={8}>
              <Pagination
                totalRows={totalCount}
                pageChangeHandler={setPage}
                rowsPerPage={perPage}
                setRowsPerPage={setPerPage}
                currentPage={page}
              />
            </Col>
          </Row>


          <div className="position-relative">
            {searchLoading && <OvalLoading />}
            <PaginatedTable
              columns={columns}
              data={productData || []}
              isLoading={searchLoading}
            />
          </div>
          <Pagination
            totalRows={totalCount}
            pageChangeHandler={setPage}
            rowsPerPage={perPage}
            setRowsPerPage={setPerPage}
            currentPage={page}
          />
        </div>
      </div>
    </div>
  )
}

export default ProductInformation
