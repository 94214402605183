export const DELIVERY_TIMES = [
  "",
  "1-2 werkdagen levertijd",
  "2-3 werkdagen levertijd",
  "3-5 werkdagen levertijd",
  "4-5 werkdagen levertijd",
  "5-8 werkdagen levertijd",
  "10-14 werkdagen levertijd",
  "1-2 weken levertijd",
  "2-3 weken levertijd",
  "3-5 weken levertijd",
]