import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
} from "reactstrap";
import Breadcrumb from "../../components/Common/Breadcrumb";
import avatar from "../../assets/images/users/avatar-1.jpg";
// import { GET_USER } from "graphql/queries/user";
// import useCustomQuery from "../../graphql/hooks/useCustomQuery";
// import useCustomMutation from "../../graphql/hooks/useCustomMutation";
import { useParams } from "react-router-dom";
// import { UPDATE_USER_MUTATION } from "../../graphql/mutations/user";
import { toast } from "react-toastify"
import moment from "moment";


function ViewUser() {
  const params = useParams();
  const [idx, setidx] = useState(1);
  const [userId, setUserId] = useState();
  const [userData, setUserData] = useState([])
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState();
  const [totalOrders, setTotalOrders] = useState("");
  const [totalRevenue, setTotalRevenue] = useState("");
  const [firstOrderedAt, setFirstOrderedAt] = useState();
  const [lastOrderedAt, setLastOrderedAt] = useState("");
  const [loyaltyNumber, setLoyaltyNumber] = useState(0);
  const [socialPlatform, setSocialPlatform] = useState("");
  const [platform, setPlatform] = useState("");
  const [blackList, setBlackList] = useState("");

  var [UpdateUser, { loading, error }] = useCustomMutation(
    UPDATE_USER_MUTATION
  )

  var { loading, error, data, execute } = useCustomQuery(GET_USER, {
    variables: { getUserByIdId: params.userId },
  })


  useEffect(() => {

    if (data != null) {
      const baseData = data?.GetUserById
      setName(baseData?.fullName)
      setEmail(baseData?.email)
      setPhoneNumber(baseData?.phoneNum)
      setTotalOrders()
      setTotalRevenue()
      setFirstOrderedAt(moment(baseData?.firstOrderAt).format('MM/DD/YYYY hh:mm:ss a'))
      setLastOrderedAt()
      setLoyaltyNumber(baseData?.loyaltyPoints)
      // setSocialPlatform(baseData.platform)
      setPlatform(baseData?.platform)
      setBlackList(baseData?.blacklistedAt)
    }

  }, [data])


  const updateProfile = (e) => {
    e.preventDefault();



    const allInputs = {
      fullName: name,
      email: email,
      phoneNum: phoneNumber,
      // totalOrders: totalOrders,
      // totalRevenue: totalRevenue,
      firstOrderAt: firstOrderedAt,
      // lastOrderedAt: lastOrderedAt,
      loyaltyPoints: parseInt(loyaltyNumber),
      socialPlatform: socialPlatform,
      platform: platform,
      // blackList: blackList,
    }


    UpdateUser("UpdateUser", { input: allInputs, updateUserId: params.userId }).then(user => {
      if (user) {
        toast.success("Account Updated Successfully!")
      }
    })
      .catch(err => {

      })
  }



  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}

          <Breadcrumb title="Skote" breadcrumbItem="Profile" />

          <Row>
            <Col lg="12">
              {/* {error && error ? <Alert color="danger">{error}</Alert> : null}
              {success ? <Alert color="success">{success}</Alert> : null} */}

              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="ms-3">
                      <img
                        src={avatar}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <h5>{name}</h5>
                        <p className="mb-1">{email}</p>
                        <p className="mb-0">Id no: #{idx}</p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <h4 className="card-title mb-4">Change Profile</h4>

          <Card>
            <CardBody>
              <Form
                className="form-horizontal"
                onSubmit=""
              >
                <div className="form-group">
                  <Label className="form-label">Full Name</Label>
                  <Input
                    name="fullname"
                    className="form-control"
                    placeholder="Enter Full Name"
                    type="text"
                    value={name}
                    disabled={true}
                    onChange={(e) => setName(e.target.value)}

                  />
                  <Label className="form-label">Phone Number</Label>
                  <Input
                    name="phoneNumber"
                    className="form-control"
                    placeholder="Enter Phone Number"
                    type="number"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}

                  />
                  <Label className="form-label">Email</Label>
                  <Input
                    name="email"
                    className="form-control"
                    placeholder="Enter Email"
                    type="email"
                    disabled={true}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}

                  />
                  <Label className="form-label">Total orders</Label>
                  <Input
                    name="totalOrders"
                    className="form-control"
                    placeholder="Enter Total orders"
                    type="number"
                    value={totalOrders}
                    onChange={(e) => setTotalOrders(e.target.value)}

                  />
                  <Label className="form-label">Total Revenue</Label>
                  <Input
                    name="totalRevenue"
                    className="form-control"
                    placeholder="Enter Total Revenue"
                    type="number"
                    value={totalRevenue}
                    onChange={(e) => setTotalRevenue(e.target.value)}

                  />
                  <Label className="form-label">First Ordered At</Label>
                  <Input
                    name="firstOrderedAt"
                    className="form-control"
                    placeholder="Enter First Ordered At..."
                    type="datetime-local"
                    value={firstOrderedAt}
                    onChange={(e) => setFirstOrderedAt(e.target.value)}

                  />
                  <Label className="form-label">Last Ordered At</Label>
                  <Input
                    name="lastOrderedAt"
                    className="form-control"
                    placeholder="Enter Last Ordered At..."
                    type="datetime-local"
                    value={lastOrderedAt}
                    onChange={(e) => setLastOrderedAt(e.target.value)}

                  />
                  <Label className="form-label">Loyalty number</Label>
                  <Input
                    name="loyaltyNumber"
                    className="form-control"
                    placeholder="Enter Loyalty number"
                    type="number"
                    value={loyaltyNumber}
                    onChange={(e) => setLoyaltyNumber(e.target.value)}

                  />
                  <Label className="form-label">Social Platform</Label>
                  <Input
                    name="socialPlatform"
                    className="form-control"
                    placeholder="Enter Social Platform"
                    type="text"
                    value={socialPlatform}
                    onChange={(e) => setSocialPlatform(e.target.value)}

                  />
                  <Label className="form-label">Platform</Label>
                  <Input
                    name="platform"
                    className="form-control"
                    placeholder="Enter Platform"
                    type="text"
                    value={platform}
                    onChange={(e) => setPlatform(e.target.value)}

                  />
                  <Label className="form-label">Blacklist At</Label>
                  <Input
                    name="blackList"
                    className="form-control"
                    placeholder="Enter Blacklist"
                    type="datetime-local"
                    value={blackList}
                    onChange={(e) => setBlackList(e.target.value)}

                  />



                  {/* <Input name="idx" value={idx} type="hidden" /> */}
                </div>
                <div className="text-center mt-4">
                  <Button onClick={updateProfile} type="submit" color="danger">
                    Update Profile
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ViewUser
